function ticketAndRemiseController($localStorage, $state, $scope, WS, $window, $compile, $parse,$translate) {
    'ngInject';
    const vm = this;

    vm.getTicket = ()=>{
        WS.get('ticket_cadeaux').then(
            (response) => {
                vm.ticket_cadeaux = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    vm.getRmiseEspece = ()=>{
        WS.get('remise_espece').then(
            (response) => {
                vm.remise_espece = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };
    
    vm.saveTicket = () => {
        WS.post('ticket_cadeaux', vm.ticket).then(
            (response) => {
                vm.ticket={};
                const new_ticket = response.data.data;
                vm.ticket_cadeaux = [new_ticket, ...vm.ticket_cadeaux];
                swal('Succé', 'ticket ajouté avec succé', 'success');
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    vm.updateTicket = (ticket) => {
        WS.post('ticket_cadeaux/updateTicket', ticket).then(
            (response) => {
                if(response.data.success){
                    swal('Succé', 'ticket modifié avec succé', 'success'); 
                }
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    vm.deleteTicket = (ticket) => {
        swal({
            title: 'Supprimer',
            text: $translate.instant("Voulez vous vraiment supprimer cette ticket!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.post('ticket_cadeaux/deleteTicket', ticket).then(
                (response) => {    
                    if(response.data.success){
                        vm.ticket_cadeaux = _.reject(vm.ticket_cadeaux, memo => memo.id == ticket.id );
                        swal('Succé', 'ticket supprimé avec succé', 'success'); 
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error);
                }
            );
        }, function(error) {
            console.log(error);
        });
         
    };

    vm.saveRemise = () => {
        WS.post('remise_espece', vm.remise).then(
            (response) => {
                vm.remise={};
                const new_remise = response.data.data;
                vm.remise_espece = [new_remise, ...vm.remise_espece];
                swal('Succé', 'Remise ajouté avec succé', 'success');
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    vm.updateRemise = (remise) => {
        WS.post('remise_espece/updateRemise', remise).then(
            (response) => {
                if(response.data.success){
                    swal('Succé', 'Remise modifié avec succé', 'success'); 
                }
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    vm.deleteRemise = (remise) => {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette remise!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.post('remise_espece/deleteRemise', remise).then(
                (response) => {    
                    if(response.data.success){
                        vm.remise_espece = _.reject(vm.remise_espece, memo => memo.id == remise.id );
                        swal('Succé', 'remise supprimé avec succé', 'success'); 
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error);
                }
            );
        }, function(error) {
            console.log(error);
        });
         
    };
    
}
export default {
    name: 'ticketAndRemiseController',
    fn: ticketAndRemiseController
};
