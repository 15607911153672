function Poids() {

  return function (value) {

    value = value ? Number(value) : 0;

    var new_number = 0;
    var unite = '';
    if (value >= 1000 && value < 1000000) {
      var new_number = value / 1000;
      new_number = new_number.toFixed(2);
      unite = 'kg';
    } else if (value >= 1000000) {
      var new_number = value / 1000000;
      new_number = new_number.toFixed(2);
      unite = 'T';
    } else if (value < 1000) {
      var new_number = value;
      new_number = new_number.toFixed(2);
      unite = 'g';
    }

    if (Number(new_number) == 0) {
      return '';
    } else {
      return new_number + ' '+ unite;
    }
  };


}

export default {
  name: 'Poids',
  fn: Poids
};

