function kpiController(AppSettings, DatatableLang, $rootScope, $scope, WS, $window, $compile, NgMap, Excel, $timeout, $anchorScroll, $translate) {
    'ngInject';
    const vm = this;
    vm.infouser = {};
    vm.filter = {};
    vm.currentDate = moment().format("DD/MM/YYYY");
    vm.kpi_type_depot = '1';
    vm.updateobjectif = {};

    // WS.getScache('pays').then(function (response) {
    //     vm.getpays = response.data;
    //     vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
    //     $scope.$apply();
    // }, function (error) {
    //     console.log(error);
    // });

    // vm.keymaps = AppSettings.keymaps;
    // vm.jours = [];
    // for (var i = 1; i < 32; i++) {
    //     var i = (i < 10) ? '0' + i : i;
    //     vm.jours.push(i);
    // }

    vm.exportTableHtml = function (tableId, title = 'Rapport') {



        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);


    };
    
    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getFournisseurs = function() {
        WS.get('fournisseurs')
            .then(function(response) {
                if (response.status == 200) {
                    vm.fournisseurs = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                vm.selectgammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.table2excel = function (tableId) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "rapport_par_periode-" + moment().format('YYYY-MM-DD');

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.exporttableHtml = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'Rapport';

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.selectProduits = function () {
        WS.get('produits/selectProduit')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectProduitGamme = function () {
        WS.get('produits/selectProduitGamme')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.month = moment().format('YYYY-MM');

    /**
     * @return jour de repot
     */

    vm.getFirstSociete = function () {
        WS.getData('societes/getFirstSociete').then(
            function (response) {
                vm.societe_global = response.data;
                vm.jour_repos = (response.data.jour_repos) ? response.data.jour_repos : 'dimanche';
                console.log('vm.jour_repos', vm.jour_repos);
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };
    vm.kpi_dd = function () {
        _.mixin({
            capitalize: function (string) {
                return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
            }
        });
        var monthname = moment(vm.month).format('MMMM');
        vm.monthname = _(monthname).capitalize();
        vm.yearname = moment(vm.month).format('YYYY');
        vm.startOfMonth = moment(vm.month).startOf('month').format('YYYY-MM-DD');
        vm.endOfMonth = moment(vm.month).endOf('month').format('DD');
        vm.alldays = [];
        var countOutDim = 0;
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
            i = (i <= 9) ? '0' + i : i;
            vm.alldays.push({
                day: i,
                name: moment(vm.month + '-' + i).format('ddd')
            });
            if (moment(vm.month + '-' + i).format('dddd') != vm.jour_repos) {
                countOutDim++;
            }

        }
        vm.countOutDim = countOutDim;
    };

    vm.kpi_objectifs = function () {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme
        };
        //$scope.$watch('vm.jour_repos', function(newModel, oldModel) {
        WS.getData('rapports/ObejectifsByCommercialsMonth', data).then(function (response) {
            vm.tableobjectifs = [];
            response.data.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                var nbr_jour_travaille = 0;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    //console.log(day_obj,vm.jour_repos, vm.endOfMonth);
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != vm.jour_repos) {


                        days_objs.push({
                            chiffre: element.chiffre,
                            value: element.obj_visite,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                        nbr_jour_travaille++;

                    } else {
                        days_objs.push({
                            chiffre: 0,
                            value: 0,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.tableobjectifs.push({
                    code: element.code,
                    nom: element.nom,
                    prenom: element.prenom,
                    user: element.nom + ' ' + element.prenom,
                    days_objs: days_objs,
                    total: days_objs.reduce((s, f) => s + f.value, 0),
                    totalchiffre: days_objs.reduce((s, f) => s + f.chiffre, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });

            var tabtotal = [];
            var tabtotalchiffre = [];
            vm.tableobjectifs.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotal[ind] = (tabtotal[ind]) ? tabtotal[ind] + obj.value : obj.value;
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });

            vm.tabtotal = tabtotal;


            vm.tabtotalchiffre = tabtotalchiffre;

            vm.totalend = tabtotal.reduce((s, f) => s + f, 0);
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_trav = vm.tableobjectifs.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
        //});
    };

    vm.kpi_visites = function () {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme
        };
        WS.getData('rapports/kpi_visites', data).then(function (response) {
            var tabvisites = [];
            _.each(response.data.users, (user,indUser) => {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.visites.forEach(function (visite, indVisite) {
                    if (user.code == visite.user_code) {
                        tabusers.push({
                            date_visite: visite.date_visite,
                            nbr_visite: visite.nbr_visite,
                            day: moment(visite.date_visite).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    _.each(tabusers, (element, index) => {
                        if (element.date_visite == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_visite: date_trait,
                            nbr_visite: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_visite).getTime());
                });
                tabvisites.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    visites: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_visite, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabvisites = tabvisites;
            var totalendObjs = 0;
            _.each(vm.tabvisites, (visite, indexVis)=>{
            //vm.tabvisites.forEach(function (visite, indexVis) {
                _.each(vm.tableobjectifs, (objectif, indexObj)=>{
                //vm.tableobjectifs.forEach(function (objectif, indexObj) {
                    if (visite.code == objectif.code) {
                        var filteredItems = _.filter(visite.visites, function (item, i) {
                            return item.nbr_visite != 0;
                        });

                        var dates = _.pluck(filteredItems, 'date_visite');

                        var totobjres = 0;

                        _.each(objectif.days_objs, function (el, index) {
                            //console.log(_.contains(dates, el.date_visite), dates, el.date_visite);
                            if (_.contains(dates, el.date_visite) == true) {
                                totobjres += Number(el.value);
                            }
                        });

                        vm.tabvisites[indexVis].totalObjRealise = totobjres;
                        totalendObjs += totobjres;
                    }
                });
            });

            var tabtotalVisite = [];
            _.each(vm.tabvisites,(element, index)=>{
                _.each(element.visites,(obj, ind) =>{
                    tabtotalVisite[ind] = (tabtotalVisite[ind]) ? tabtotalVisite[ind] + obj.nbr_visite : obj.nbr_visite;
                });
            });
            vm.tabtotalVisite = tabtotalVisite;
            vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);
            //vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_visite = vm.tabvisites.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            vm.totalendObjs = totalendObjs;

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_ventes = function (type = '') {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
            type: type,
        };
        WS.getData('rapports/kpi_ventes', data).then(function (response) {
            var tabventes = [];
            
            _.each(response.data.users, (user, indUser) =>{
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            nbr_vente: vente.nbr_vente,
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            nbr_vente: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                tabventes.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_vente, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabventes = tabventes;

            var tabtotalvente = [];
            vm.tabventes.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    if (tabtotalvente[ind]) {
                        tabtotalvente[ind].nbr_vente = (tabtotalvente[ind].nbr_vente) ? tabtotalvente[ind].nbr_vente + obj.nbr_vente : obj.nbr_vente;
                    } else {
                        tabtotalvente.push({ nbr_vente: obj.nbr_vente, date_vente: obj.date_vente });

                    }
                });
            });
            vm.tabtotalvente = tabtotalvente;
            vm.totalendvente = tabtotalvente.reduce((s, f) => s + f.nbr_vente, 0);

            vm.total_nbr_jour_vente = vm.tabventes.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            // console.log('tabtotalvente', tabtotalvente);
            // console.log('totalendvente', vm.totalendvente);

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_total_ttc = function (type = '') {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
            type: type,
        };
        WS.getData('rapports/kpi_total_ttc', data).then(function (response) {
            var tabttc = [];
            _.each(response.data.users, (user, indUser) =>{
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            total_ttc: parseFloat(vente.total_ttc),
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });
                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            total_ttc: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                var total = tabusers.reduce((s, f) => s + f.total_ttc, 0);
                tabttc.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: parseFloat(total).toFixed(0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabttc = tabttc;

            var tabtotalttc = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    tabtotalttc[ind] = (tabtotalttc[ind]) ? parseFloat(tabtotalttc[ind]) + obj.total_ttc : obj.total_ttc;
                    tabtotalttc[ind] = parseFloat(tabtotalttc[ind]).toFixed(0);
                });
            });
            vm.tabtotalttc = tabtotalttc;

            var totalendttc = tabtotalttc.reduce((s, f) => parseFloat(s) + parseFloat(f), 0);
            vm.totalendttc = parseFloat(totalendttc).toFixed(0);

            vm.total_nbr_jour_ttc = vm.tabttc.reduce((s, f) => s + f.nbr_jour_travaille, 0);

            //vm.tabtotalttcPercent //ttc percent
            /**
             * debut percent vente ttc
             */
            var tabtotalttcPercent = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {

                    vm.tabtotalttc.forEach(function (e_total, i_total) {
                        if (i_total == ind) {
                            if (e_total > 0) {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + (obj.total_ttc * 100 / e_total) : (obj.total_ttc * 100 / e_total);
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            } else {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + 0 : 0;
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            }
                        }
                    });


                });
            });
            vm.tabtotalttcPercent = tabtotalttcPercent;
            var totalendttcPercent = tabtotalttcPercent.reduce((s, f) => parseFloat(s) + parseFloat(f), 0) / tabtotalttcPercent.length;
            vm.totalendttcPercent = parseFloat(totalendttcPercent).toFixed(0);

            /**
             * fin percent vente ttc
             */


            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_total_ttc_commande = function (type = '') {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
            type: type,
        };
        WS.getData('rapports/kpi_total_ttc_commande', data).then(function (response) {
            var tabttc = [];
            _.each(response.data.users, (user, indUser) =>{
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            total_ttc: parseFloat(vente.total_ttc),
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });
                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            total_ttc: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                var total = tabusers.reduce((s, f) => s + f.total_ttc, 0);
                tabttc.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: parseFloat(total).toFixed(0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabttc_commande = tabttc;

            var tabtotalttc = [];
            vm.tabttc_commande.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    tabtotalttc[ind] = (tabtotalttc[ind]) ? parseFloat(tabtotalttc[ind]) + obj.total_ttc : obj.total_ttc;
                    tabtotalttc[ind] = parseFloat(tabtotalttc[ind]).toFixed(0);
                });
            });
            vm.tabtotalttc_commande = tabtotalttc;

            var totalendttc = tabtotalttc.reduce((s, f) => parseFloat(s) + parseFloat(f), 0);
            vm.totalendttc_commande = parseFloat(totalendttc).toFixed(0);

            vm.total_nbr_jour_ttc_commande = vm.tabttc_commande.reduce((s, f) => s + f.nbr_jour_travaille, 0);

            //vm.tabtotalttcPercent //ttc percent
            /**
             * debut percent vente ttc
             */
            var tabtotalttcPercent = [];
            vm.tabttc_commande.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {

                    vm.tabtotalttc_commande.forEach(function (e_total, i_total) {
                        if (i_total == ind) {
                            if (e_total > 0) {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + (obj.total_ttc * 100 / e_total) : (obj.total_ttc * 100 / e_total);
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            } else {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + 0 : 0;
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            }
                        }
                    });


                });
            });
            vm.tabtotalttcPercent_commande = tabtotalttcPercent;
            var totalendttcPercent = tabtotalttcPercent.reduce((s, f) => parseFloat(s) + parseFloat(f), 0) / tabtotalttcPercent.length;
            vm.totalendttcPercent_commande = parseFloat(totalendttcPercent).toFixed(0);

            /**
             * fin percent vente ttc
             */


            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLinettc = function (type = '') {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
            type: type,
        };
        WS.getData('rapports/kpiChartLinettc', data).then(function (response) {
            var tabusers = [];
            response.data.ventes.forEach(function (vente, indvente) {
                tabusers.push({
                    date_vente: vente.date_vente,
                    total_ttc: vente.total_ttc,
                    day: moment(vente.date_vente).format('DD ddd')
                });

            });

            //tabusers
            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                tabusers.forEach(function (element, index) {
                    if (element.date_vente == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    tabusers.push({
                        date_vente: date_trait,
                        total_ttc: 0,
                        day: moment(date_trait).format('DD ddd')
                    });
                }
            }

            tabusers = _.sortBy(tabusers, function (node) {
                return (new Date(node.date_vente).getTime());
            });


            /**
             * traitement d'objectif 
             */
            vm.traitementobjectif = [];
            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != vm.jour_repos) {

                        days_objs.push({
                            chiffre: element.chiffre,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    } else {
                        days_objs.push({
                            chiffre: 0,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.traitementobjectif.push({
                    days_objs: days_objs
                });
            });

            var tabtotalchiffre = [];
            vm.traitementobjectif.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.moyennetotalchiffre = vm.totalendchiffre / vm.tabtotalchiffre.length;
            /** 
             * fin du traitement 
             */

            tabusers.forEach(function (element, index) {
                tabtotalchiffre.forEach(function (totalchiffre, indexchiffre) {
                    if (index == indexchiffre) {
                        tabusers[index].objectif = totalchiffre;
                    }
                });
            });
            vm.chartlinettc = tabusers;
            /*console.log('vm.chartlinettc', vm.chartlinettc);
            console.log('vm.tabtotalchiffre', tabtotalchiffre);*/

            var chart = AmCharts.makeChart("chartlinettc", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": vm.chartlinettc,
                "valueAxes": [{
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetotalchiffre.toFixed(3),
                        "value": vm.moyennetotalchiffre,
                        "toValue": vm.moyennetotalchiffre,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "CA (TTC)",
                    "type": "column",
                    "valueField": "total_ttc",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLineTauxSuccess = function (type = '') {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
            type: type,
        };
        WS.getData('rapports/kpiChartLineTauxSuccess', data).then(function (response) {
            var data = [];
            response.data.visite.forEach(function (visite, indexVis) {

                response.data.vente.forEach(function (vente, indexVen) {
                    if (visite.date_visite == vente.date_visite) {
                        var obj = {};
                        obj = {
                            date_visite: vente.date_visite,
                            day: moment(vente.date_visite).format('DD ddd'),
                            taux: parseFloat(vente.nbr_vente * 100 / visite.nbr_total).toFixed(0)
                        }
                        data.push(obj);
                    }
                });
            });

            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                data.forEach(function (element, index) {
                    if (element.date_visite == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    data.push({
                        date_visite: date_trait,
                        day: moment(date_trait).format('DD ddd'),
                        taux: 0
                    });
                }
            }
            vm.totalendtaux_success = data.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_success = vm.totalendtaux_success / data.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var data = _.sortBy(data, 'date_visite');

            var chart = AmCharts.makeChart("kpiChartLineTauxSuccess", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": data,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_success.toFixed(2),
                        "value": vm.moyennetaux_success,
                        "toValue": vm.moyennetaux_success,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de succès (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLineTauxVisites = function () {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
        };
        WS.getData('rapports/kpiChartLineTauxVisites', data).then(function (response) {

            var objectifs = [];

            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var date_trait = moment(vm.month + '-' + i).format("YYYY-MM-DD");
                    var is_dim = moment(vm.month + '-' + i).format('dddd');
                    var day_obj = moment(vm.month + '-' + i).format('DD ddd');
                    var j = 0;
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && is_dim != vm.jour_repos) {
                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                                objectifs[indexObj].taux = parseFloat(objectif.taux) + element.obj_visite;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: element.obj_visite,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    } else {

                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: 0,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    }
                }

            });

            objectifs.forEach(function (element, index) {
                var i = 0;
                response.data.visite.forEach(function (visite, indexVis) {
                    if (element.date_visite == visite.date_visite) {
                        if (element.taux == 0 || visite.nbr_total == 0) {
                            var taux = 0;
                        } else {
                            var taux = visite.nbr_total * 100 / element.taux;
                            if (taux > 100) {
                                taux = 100;
                            }
                        }
                        objectifs[index].taux = taux.toFixed(0);
                        i++;
                    }
                });
                if (i == 0) {
                    objectifs[index].taux = 0;
                }
            });
            vm.totalendtaux_visite = objectifs.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_visite = vm.totalendtaux_visite / objectifs.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var chart = AmCharts.makeChart("kpiChartLineTauxVisites", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": objectifs,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_visite.toFixed(2),
                        "value": vm.moyennetaux_visite,
                        "toValue": vm.moyennetaux_visite,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de Visite (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.getVenteStatbyUser = function (user_code, date_vente) {
        //alert(user_code + ' ' +date_vente);
    };

    vm.getTotalVenteStat = function (date_vente) {
        //alert(date_vente);
    };

    $rootScope.$on('$translateChangeSuccess', function () {
        vm.allmonths = [
            '',
            $translate.instant('Janvier'), $translate.instant('Fevrier'),
            $translate.instant('Mars'), $translate.instant('Avril'),
            $translate.instant('Mai'), $translate.instant('Juin'),
            $translate.instant('Juillet'), $translate.instant('Aout'),
            $translate.instant('Septembre'), $translate.instant('Octobre'),
            $translate.instant('Novembre'), $translate.instant('Decembre'),
        ];

    });

    /**
     * initialisation des dates
     */
    $(document).ready(function () {

        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.month = date;
                vm.kpi_reload_all_tables();
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    })

    vm.getInfoCommercial = function (code) {
        vm.infouser = _.findWhere(vm.selectusers, { code: code });
        //console.log(vm.infouser);
    }

    vm.selectedUser = (user_code) => {
        vm.currentUser = _.find(vm.commercial, (memo) => memo.code == user_code);
        //console.log("currentUser" , vm.currentUser);
    }


    vm.getCurrentObjectifByUser = (user_code) => {
        //console.log(vm.month, user_code);
        vm.updateobjectif.user_code = user_code;
        var data = {
            month: vm.month,
            user_code
        };

        WS.getData('rapports/getCurrentObjectifByUser', data)
            .then(function (response) {
                if (response.status == 200) {
                    if (response.data) {
                        vm.updateobjectif = response.data;
                    }
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateObjectifForUser = () => {
        var data = {
            user_code: vm.obj_user_code,
            obj_visite: vm.updateobjectif.obj_visite,
            chiffre: vm.updateobjectif.chiffre,
            date_debut: vm.updateobjectif.month_debut,
            date_fin: vm.updateobjectif.month_fin,
        };

        console.log(data);
        WS.post('rapports/storeObjectifByUser', data)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    if (response.data.success) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Objectif modifiée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });

                    } else {
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant(response.data.message),
                            'warning'
                        );
                    }
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getRecetteFromMvtClient = () => {
        var data = {
            commercial: vm.kpi_commercial,
            fournisseur: vm.kpi_fournisseur,
            type_depot: vm.kpi_type_depot,
            month: vm.month,
            produit_code: vm.kpi_produit,
            gamme_code: vm.kpi_gamme,
        };

        WS.getData('kpi/getRecetteFromMvtClient', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_recettes = response.data.commerciaux;
                    vm.recette_total_by_date = response.data.total_by_date;

                    vm.recette_total_end = response.data.recette_total_end;
                    vm.recette_total_end_nbr_jour = response.data.recette_total_end_nbr_jour;

                    //console.log('vm.list_recettes', vm.list_recettes);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.kpi_reload_all_tables = () => {
        vm.kpi_dd();
        vm.reload_nbr_pdv_planifier=true;
        vm.reload_show_nbr_cls_visites=true;
        vm.reload_show_nbr_cls_ventes=true;
        vm.reload_show_chiffre_daff_net=true;
        vm.reload_show_chiffre_daff_net_commande=true;
        vm.reload_show_recettes=true;
        vm.reload_show_tx_visites=true;
        vm.reload_show_tx_succes=true;
        vm.reload_show_contrib_van=true;
        vm.reload_show_dropsize=true;
        vm.reload_show_objectif_ca=true;
        vm.reload_show_obj_vs_real=true;
        vm.reload_show_chiffre_daff_net=true;
        vm.reload_show_graph_tx_succes=true;
        vm.reload_show_graph_tx_visites=true;
    };

}
export default {
    name: 'kpiController',
    fn: kpiController
};