function carburantController(DatatableLang, $state, $scope, WS, $window ,$translate) {
    'ngInject';
    const vm = this;
    vm.regions = {};

    vm.getCarburants = function() {
        WS.get('carburants/withimage')
            .then(function(response) {
                if (response.status == 200) {
                    vm.carburants = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getCarburantByDate = function(date_debut = null, date_fin = null, commercial = null) {
        var url = 'carburants/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.carburants = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.exportCarburantByDate = function(date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'carburants/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function(response) {
            var filename = "Carburant By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Carburant By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Carburant By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'codeCommercial', title: 'Code' },
                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'ticket_code', title: 'Ticket' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'date', title: 'Date' },
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function(error) {
            console.log(error);
        });
    };

    vm.getCoutVisite = (date_debut = null, date_fin = null, code) => {
        var url = 'carburants/cout/' + date_debut + '/' + date_fin + '/' + code;
        //console.log(url);
        WS.get('carburants/cout/' + date_debut + '/' + date_fin + '/' + code)
            .then(function(response) {
                vm.coutvisites = response.data;

                jQuery.noConflict();
                $('#coutvisiteModal').modal('show');
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.getTotalMontant = function(list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['montant']);
        }
        return total;
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectDepots = function() {
        WS.get('depots/selectDepot').then(
            function(response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.addCarburant = function(carburant) {
   
        WS.post('carburants/web', carburant, 'POST')
        .then(function (response) {
            if (response.status == 200) {
                swal(
                    'Succès',
                    'carburant ajouté avec Succès',
                    'success'
                ).then(function() {
                    $window.location.reload();
                });
            } else {
                swal('Error', response.data.message, 'error');
            }
        }, function (error) {
            console.log(error);
        });
     };
     vm.update = function(carburant) {
        WS.put('carburants/' + carburant.id, carburant)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'carburant modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.delete = function(id) {

        swal({
            title: $translate.instant('Carburant'),
            text: $translate.instant("voulez vous vraiment supprimer!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('carburants/' + id).then(
                function() {
                    swal(
                       $translate.instant('Succès'),
                       $translate.instant('Carburant supprimée avec Succès.'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                       $translate.instant('Attention!'),
                       $translate.instant('vous ne pouvez pas supprimer '),
                        'warning'
                    );
                }
            );
        });
    }

}
export default {
    name: 'carburantController',
    fn: carburantController
};