export const fr_FR = {
    'commercial': 'commercial',
    'vente': 'Vente',
    'commande': 'Commande',
    'Commande': 'Commande',
    'Bloquer': 'Bloquer',
    'bl': 'BL',
    'clients_visite': 'Clients Visités',
    'recrutement_client': 'Recrutement Client',
    'recette': 'Recette',
    'credit': 'Crédit',
    'espece': 'Espèce',
    'cheques': 'Chèques',
    'recouvrements': 'Recouvrements',
    'tx_vente': 'Tx Vente',
    'tx_visite': 'Tx Visite',
    'Nv Client': 'Nv Client',
    'R.tourne': 'R.tournée',
    'visites': 'visites',
    'nombre': 'Nombre',
    'Historique': 'Historique',
    'tournees': 'tournées',
    'tournee': 'tournée',
    'Global societe': 'Société Globale',
    'societe': 'Société',
    'gouvernorat': 'gouvernorat',
    'Region': 'Région',
    'top': 'Top',
    'gamme': 'Gamme',
    'famille': 'Famille',
    'sous famille': 'Sous Famille',
    'Box': 'Box',
    'Superviseur': 'Superviseur',
    'Vente par Marque': 'Vente par marque',
    'Vente par Famille': 'Vente par famille',

    //dates
    'aujourdhui': 'Aujourd\'hui',
    'mois': 'Mois',
    'date': 'Date',
    'jour': 'Jour',
    'date debut': 'Date début',
    'date fin': 'Date fin',
    'Heure debut': 'Heure début',
    'Heure fin': 'Heure fin',
    'TimeWork': 'Temps des traveaux',
    'TimeOut': 'hors travaux',
    'Kilometrage': 'Kilometrage',
    'KD': 'KD',
    'KF': 'KF',
    'Distance': 'Distance',
    'Temps': 'Temps',
    'annee': 'Année',
    'Janvier': 'Janvier',
    'Fevrier': 'Fevrier',
    'Mars': 'Mars',
    'Avril': 'Avril',
    'Mai': 'Mai',
    'Juin': 'Juin',
    'Juillet': 'Juillet',
    'Aout': 'Aout',
    'Septembre': 'Septembre',
    'Octobre': 'Octobre',
    'Novembre': 'Novembre',
    'Decembre': 'Decembre',
    'distribution': 'Distribution',
    'kpi': 'KPI',

    // menu
    'dashboard': 'Dashboard',
    'analyses': 'Analyses',
    'routing': 'Routing',
    'utilisateurs': 'Utilisateurs',
    'parametrages': 'Parametrages',
    'clients': 'Clients',
    'client': 'Client',
    'tarification': 'Tarification',
    'objectif': 'Objectif',
    'fidelites': 'Fidelités',
    'produits': 'Produits',
    'produit': 'Produit',
    'chargements': 'Chargements',
    'equipements': 'Equipements',
    'mouvements': 'Mouvements',
    'mouvement': 'Mouvement',
    'dn': 'DN',
    'carburant': 'Carburant',
    'stock': 'Stock',
    'rapports': 'Rapports',
    'caisse': 'Caisse',
    'achat': 'Achat',
    'todo list': 'Choses à faire',
    'devise': 'Devise',
    'integration': 'Integration',
    'erp': 'ERP',
    'notes': 'Notes',
    'Correction Recouvrement': 'Correction Recouvrement',


    // analyses
    'journalier': 'Journalier',
    'periodique': 'Périodique',
    'periode': 'Période',
    'Vendeur': 'Vendeur',
    'Rapport Journalier Produit': 'Rapport Journalier Produit',
    'Rapport Journalier SousFamille': 'Rapport Journalier sousfamille',
    'Rapport Commercial/Mois': 'Rapport Commercial/Mois',
    'Rapport Tournée': 'Rapport Tournée',
    'Rapport Periodique': 'Rapport Périodique',
    'Vente Par Region': 'Vente Par Région',
    'Vente Periode': 'Vente Période',
    'Vente Famille/Commercial': 'Vente Famille/Commercial',
    'Vente Famille/Gamme': 'Vente Famille/Gamme',
    'Vente Client_Date': 'Vente Client_Date',
    'DN_Vendeur': 'DN_Vendeur',
    'Vente sousFamille_Client': 'Vente sousFamille_Client',
    'Vente et DN par Produit': 'Vente et DN par Produit',
    'Vente et DN par Produit region': 'Vente et DN par Produit region',
    'Analyse Tournee': 'Analyse Tournée',
    'Clients Inactifs': 'Clients Inactifs',
    'Vente/Commercial': 'Vente/Commercial',
    'Suivi Commercial': 'Suivi Commercial',
    'Gouvernorat': 'Gouvernorat',
    'Zone': 'Zone',
    'Stat Chargement': 'Stat Chargement',
    'Top 10': 'Top 10',
    'Vente Client': 'Vente Client',
    'Credit par Commercial': 'Crédit par Commercial',
    'List Credit par Commercial': 'Liste Crédit par Commercial',
    'Reglement par Commercial': 'Réglement par Commercial',
    'Visites prévus par Commercial': 'Visites prévues par Commercial',
    'KPI Commercial par Mois': 'KPI Commercial par Mois',
    'type': 'type',
    'NB/BL': 'NB/BL',
    'NB/Avoir': 'NB/Avoir',
    'NB/Visites': 'NB/Visites',
    'NB/Jour': 'NB/Jour',
    'Tx succés': 'Tx succès',
    'Tx succès': 'Tx succès',
    'Client visité': 'Clients visités',
    'nbr Client/Vente': 'nbr Client/Vente',
    'Ajout Client': 'Ajout Client',
    'KPI Global': 'KPI Global',
    'Nb Client': 'Nb Client',
    'Kilometrage': 'Kilometrage',
    'Vente par produit': 'Vente par produit',
    'Produit': 'Produit',
    'Quantite': 'Quantité',
    'Montant': 'Montant',
    'Commandes TTC': 'Commandes TTC',
    'Ventes TTC': 'Ventes TTC',
    'Credit TTC': 'Crédit TTC',
    'Choisir': 'Choisir',
    'Tous': 'Tous',
    'KPI Commercial': 'KPI Commercial',
    'Rapport Vente Par Region': 'Rapport Vente Par Région',
    'Rapport DN Par Region': 'Rapport DN Par Région',
    'export': 'Export',
    'csv': 'CSV',
    'xlsx': 'XLSX',
    'Vente par Commercial': 'Vente par Commercial',
    'Anaylse vente Famille/Gamme': 'Anaylse vente Famille/Gamme',
    'Chiffre': 'Chiffre',
    'Pourcentage': 'Pourcentage',
    'Secteur': 'Secteur',
    'Adresse': 'Adresse',
    'Code': 'Code',
    'Total': 'Total',
    'ANALYSE TOURNEE': 'Analyse tournée',
    'LUNDI': 'LUNDI',
    'MARDI': 'MARDI',
    'MERCREDI': 'MERCREDI',
    'JEUDI': 'JEUDI',
    'SAMEDI': 'SAMEDI',
    'DIMANCHE': 'DIMANCHE',
    'Nom': 'Nom',
    'Magasin': 'Magasin',
    'Routing': 'Routing',
    'Date création': 'Date création',
    'Operation': 'Opération',
    'KPI Commande par Gouvernorat': 'KPI Commande par Gouvernorat',
    'KPI Commande par Zone': 'KPI Commande par Zone',
    'Ecart': 'Ecart',
    'nbr produit unique': 'nbr produit unique',
    'nbr produit vendu': 'nbr produit vendu',
    'quantite vendu': 'quantitée vendue',
    'N°': 'N°',
    'Mobile': 'Mobile',
    'Dernier visite': 'Dernière visite',
    'Observation': 'Observation',
    'Encours': 'Encours',
    'Date Credit': 'Date Crédit',
    'Traite': 'Traite',
    'Retenue Avoir': 'Retenue Avoir',
    'Cause': 'Cause',
    'Commentaire': 'Commentaire',
    'prévu prochaine Visite': 'prévu prochaine Visite',
    'Nombre de PDV Planifié': 'Nombre de PDV Planifié',
    'Nombre de Clients visités': 'Nombre de Clients visités',
    'Nombre de Clients avec Ventes': 'Nombre de Clients avec Ventes',
    'Chiffre d\'affaire Net': 'Chiffre d\'affaire Net',
    'Taux de Visites %': 'Taux de Visites %',
    'Taux de Succès %': 'Taux de Succès %',
    'Contribution / Van %': 'Contribution / Van %',
    'Dropsize': 'Dropsize',
    'Objectif CA TTC': 'Objectif CA TTC',
    'Objectif Vs Réalisation %': 'Objectif Vs Réalisation %',
    'Taux de succés %': 'Taux de succès %',
    'Taux de Visites %': 'Taux de Visites %',
    'nbr de visite * 100 / objectif.total )  / v.nbr_jour_travaille': 'nbr de visite * 100 / objectif.total )  / v.nbr_jour_travaillés',
    // DN
    'Distribution numérique': 'Distribution numérique',
    'Interval': 'Intervalle',

    // clients
    'Categorie': 'Catégorie',
    'Rechercher': 'Rechercher',
    'Nombre de Client par Activité': 'Nombre de Clients par Activités',
    'Nombre de Client par Catégorie': 'Nombre de Clients par Catégories',
    'Route': 'Route',
    'En cours': 'En cours',
    'Date création': 'Date de création',
    'Action': 'Action',
    'suivant': 'Suivant',
    'precedant': 'Précedent',
    'Echeance': 'Echéance',
    'Modifier routes': 'Modifier routes',
    'Ajouter Tournee': 'Ajouter Tournée',
    'Nouvelle Route': 'Nouvelle Route',
    'hors tournee': 'Hors tournée',



    // routings
    'Matricule': 'Matricule',
    'Camion': 'Camion',
    'Route Carte': 'Route Carte',
    'Suivi': 'Suivi',
    'Debut/Fin': 'Debut/Fin',
    'Depot': 'Dépôt',
    'Moyenne': 'Moyenne',
    'Distributeur': 'Distributeur',
    'valider': 'Valider',
    'frequance': 'Fréquence',
    'facture': 'Facture',
    'RemiseEspece': 'RemiseEspece',
    'Total TTC': 'Total TTC',
    'Net a payer': 'Net à payer',

    //tarification
    'Ajouter un Tarif': 'Ajouter un tarif',
    'Tarif': 'Tarif',
    'P. TTC': 'P. TTC',
    'P. HT': 'P. HT',

    // OBJECTIFS
    'Libelle': 'Libellé',
    'Assortiment': 'Assortiment',
    'Ajouter un Objectif': 'Ajouter un Objectif',
    'prix': 'prix',
    'Ajouter': 'Ajouter',

    //fidelite
    'Ajouter Fidelite': 'Ajouter Fidélité',
    'Equivalence': 'Equivalence',
    'Points': 'Points',
    'Actif': 'Actif',

    //fournisseurs
    'Nom et Prénom': 'Nom et Prénom',
    'Grade': 'Grade',
    'Role': 'Role',
    'Metier': 'Métier',
    'Téléphone': 'Téléphone',
    'Actif': 'Actif',
    'Actualisé': 'Actualisation',
    'Ajouter un Fournisseur': 'Ajouter un fournisseur',
    'Fournisseurs': 'Fournisseurs',

    // pays
    'Pays': 'Pays',
    'Longitude': 'Longitude',
    'Latitude': 'Latitude',

    //societe
    'Societe': 'Société',
    'Fiche Societe': 'Fiche Société',
    'logo': 'logo',
    'Matricule Fiscale': 'Matricule Fiscale',
    'telephone': 'téléphone',
    'Fax': 'Fax',
    'email': 'Email',
    'mot de passe': 'Mot de passe',
    'admin': 'admin',
    'prefix_bl': 'prefix_bl',
    'prefix_devis': 'prefix_devis',
    'prefix_commande': 'prefix_commande',
    'Pied_page': 'Pied_page',
    'Entete': 'Entete',
    'Changement_prix': 'Changement_prix',
    'SMS Confirmation': 'SMS Confirmation',
    'Image': 'Image',
    'Enregistrer': 'Enregistrer',

    // Camion_Prevendeur
    'Camion_Prevendeur': 'Camion_Prevendeur',
    'O.Achat Avoir': 'O.Achat Avoir',
    'Synchronisation': 'Synchronisation',
    'Taux Avoir': 'Taux Avoir',
    'Gestion Client': 'Gestion Client',
    'Modification Client': 'Modification Client',
    'Colisage': 'Colisage',
    'Sync Client': 'Sync Client',
    'Total CMD': 'Total CMD',
    'Total Avoir': 'Total Avoir',
    'Total Recouvrement': 'Total Recouvrement',
    'Total BL': 'Total BL',
    'Modification remise': 'Modification remise',
    'Sequence Client': 'Séquence Client',
    'Plafond Credit': 'Plafond Crédit',
    'Vente client avec credit': 'Vente client avec crédit',
    'Paiement Esp': 'Paiement Esp',
    'Obligation Achat en cas d\'avoir': 'Obligation d\'achat en cas d\'avoir',
    'Consigne': 'Consigne',
    'Modifier Camion': 'Modifier Camion',

    // Equipements
    'Ajouter un Equipement': 'Ajouter un Equipement',
    'Printer': 'Printer',
    'Etat': 'Etat',
    'Adresse MAC': 'Adresse MAC',
    'Derniere sync': 'Dernière sync',

    // Affectations
    'Affectations': 'Affectations',
    'Ajouter Affectation': 'Ajouter Affectation',
    'Ajouter une delegation': 'Ajouter une délégation',
    'Model xlsx': 'Model xlsx',
    'nbr pdvs': 'nbr pdvs',

    // Delegations
    'Delegations': 'Délégations',
    'Gouvernorat': 'Gouvernorat',
    'Localites': 'Localités',
    'code postale': 'code postal',
    'Ajouter une region': 'Ajouter une région',
    'Secteurs': 'Secteurs',
    'Ajouter un Secteur': 'Ajouter un Secteur',

    //produits
    'Gratuites': 'Gratuités',
    'Pack': 'Pack',
    'Promotion': 'Promotion',
    'Télécharger le model': 'Télécharger le model',
    'TXT prix': 'TXT prix',
    'Import mise a jour prix du produit format csv/txt (colone1=vide;colone2=produit_code,colone3=vide;colone4=prix_ttc)': 'Import mise à jour prix du produit format csv/txt (colone1=vide;colone2=produit_code,colone3=vide;colone4=prix_ttc)',
    'TXT': 'TXT',
    'Import produits Wavsoft erp': 'Import produits Wavsoft erp',
    'Import Nomadis Produits Format : (txt,csv,xls,xlsx)': 'Import Nomadis Produits Format : (txt,csv,xls,xlsx)',

    //gratuite
    'Gratuités': 'Gratuités',
    'Cumulable': 'Cumulable',
    'Ajouter une gratuité': 'Ajouter une gratuité',
    'Groupe': 'Groupe',
    'Affectation Clients': 'Affectation Clients',
    'Affectation Clients Gratuite': 'Affectation Clients Gratuité',
    'Ajouter une Affectation Client': 'Ajouter une Affectation Client',

    //pack
    'Details': 'Détails',
    'Ajouter Composition': 'Ajouter Composition',
    'Numero Config': 'Numero Config',
    'Configuration': 'Configuration',
    'Ajouter une remise': 'Ajouter une remise',
    'Min': 'Min',
    'Max': 'Max',
    'Ajouter une Affectation Client': 'Ajouter une Affectation Client',
    'Ajouter une Affectation Produit': 'Ajouter une Affectation Produit',

    //MOUVEMENT
    'Numero operation': 'Numero operation',
    'Fichier Excel': 'Fichier Excel',

    //chargement 
    'Importation chargement Camion Format CSV/TXT avec separateur ;': 'Importation chargement Camion Format CSV/TXT avec séparateur ;',
    'Liste des chargements': 'Liste des chargements',
    'Demande de chargements': 'Demande de chargements',
    'Code a barre': 'Code à barre',
    'Nouveau chargement': 'Nouveau chargement',
    'Modification du chargement': 'Modification du chargement',
    'Code chargement': 'Code chargement',

    //equipement client
    'Numero de serie': 'Numero de série',
    'Nouveau utilisateur': 'Nouveau utilisateur',
    //notes
    'Liste des Notes': 'Liste des Notes',
    'Envoyé': 'Envoyé',
    'Recu': 'Recu',
    'Objet': 'Objet',

    //carburant
    'Ticket': 'Ticket',
    'Code Commercial': 'Code Commercial',

    // stocks
    'Code En Douane': 'Code En Douane',
    'BON DE SORTIE N°': 'BON DE SORTIE N°',
    'Destination': 'Destination',
    'Produit en rupture': 'Produit en rupture',
    'Valeur Stock': 'Valeur Stock',
    'Valeur': 'Valeur',
    'validite': 'Validité',

    //todo
    'Todo': 'Todo',
    'Collaborateur': 'Collaborateur',
    'Cible': 'Cible',
    'Status': 'Status',
    'Titre': 'Titre',
    'Description': 'Description',
    'Date Before': 'Date avant',
    'Duree': 'Durée',
    'PG': 'PG',
    'Priorite': 'Priorité',
    'Cloture': 'Clôture',
    'Date after': 'Date d\après',
    'Preuve': 'Preuve',

    //Rapport Visite
    'Rapport Visite': 'Rapport Visite',
    'Rapport Details': 'Rapport Details',
    'Stop': 'Stop',
    'Element': 'Elément',
    'Cloture': 'Clôture',

    //ACHAT
    'User': 'User',
    'Ajouter Commande': 'Ajouter Commande',

    //Devises
    'Devises': 'Devises',
    'Ajouter devise': 'Ajouter devise',
    'Taux': 'Taux',

    //caisses
    'Recette': 'Recette',
    'Caisse Commercial': 'Caisse Commerciale',
    'Caisses': 'Caisses',
    'Recette Aujourd\'hui': 'Recette Aujourd\'hui',
    'Journée': 'Journée',

    //params promo
    'Message Promo': 'Message Promo',
    'Parametage Mobile': 'Parametage Mobile',
    'Ligne': 'Ligne',
    'Impression Recette': 'Impression Recette',
    'Modification Mobile Client': 'Modification Mobile Client',

    'IntegrationErp': 'IntegrationErp',
    'Export': 'Export',
    'Import': 'Import',

    //message
    'Votre compte a été désactivée.': 'Votre compte a été désactivé.',
    'Votre compte a été expirée.': 'Votre compte est expiré.',
    'Erreur de verification de compte, veuillez contacter l\'admin.': 'Erreur de vérification de compte, veuillez contacter l\'admin.',

    // typevisite:
    'Stock': 'Stock',
    'Recouvrement': 'Recouvrement',
    'Exclusive Concurent ': 'Concurrent',
    'Concurent ': 'Concurrent',
    'Patron Absent': 'Patron absent',
    'Ferme': 'Fermé',
    'visite': 'Visite',
    'bl': 'BL',
    'recouvrement': 'Recouvrement',
    'commande': 'Commande',
    'avoir': 'Avoir',


    // typemouvements:
    'venteC': 'venteC',
    'chargement': 'chargement',
    'retour': 'retour',
    'Reprise': 'Reprise',
    'DLC CASSE': 'DLC CASSE',
    'dlc': 'dlc',
    'casse': 'casse',
    'retourC': 'retourC',
    'reenvoyer': 'Re-envoyer',


    'Total cheque': 'Total chèque',
    'depense': 'Dépense',
    'vente réel': 'Vente réelle',
    'vente facturé': 'Vente facturée',
    'non facturé': 'Non facturée',
    'Retour Hier': 'Retour d\hier',



    //meriam
    'ordre': 'Ordre',
    'perime': 'Perimé',
    'Lait': 'Lait',
    'Pots': 'Pots',
    'Gajou': 'Gajou',
    'Ratio': 'Ratio',
    'Vente Net': 'Vente Nette',
    'Offre Commercial': 'Offre Commerciale',
    'Encours Commercial par Client': 'Encours Commercial par Client',
    'Attention journée sans plan de tournée': 'Attention journée sans plan de tournée !!!',
    'Programé': 'Programmé',
    'Clients programmés  non visité': 'Clients programmés  non visité',
    //Users
    "Connecté": "Connexion",
    "Equipement modifié avec Succès": "Modification terminée avec succès",
    "Rechercher": "Recherche",
    "role modifié avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer ce rôle!": "Voulez-vous vraiment supprimer cet élément?",
    "Authorization roles": "Autorisations rôles",
    "Chargements": "Chargements",
    "Modifier Chargements": "Modifier chargements",
    "Demande des Chargements": "Demande de chargements",
    "Detail Demande Chargement": "Détails des demandes de chargements",
    "Chargement type": "Chargement type",
    "Consultation Commande": "Consultation des commandes",
    "Banques": "Banques",
    "Exporter bl+avoir": "Export Bl+Avoir",
    "Ajouter Caisse": "Ajout caisse",
    "Cloture Caisse": "Clôture caisse",
    "Modifier Caisse": "Modification caisse",
    "Supprimer Caisse": "Suppression caisse",
    "Quota": "Quota",
    "Ventes": "Ventes",
    "Liste Bls": "Liste des bls",
    "Détail Bl": "Détails des bls",
    "Recouvrements": "Recouvrements",
    "BL Facturé": "Bl facturé",
    "Liste User": "Liste users",
    "Ajouter User": "Ajout user",
    "Modifier User": "Modification user",
    "Supprimer User": "Suppression user",
    "Bloquer User": "Blocage user",
    "Affectation gamme user": "Affectation gamme user",
    "Listes Roles": "Listes des rôles",
    "Ajouter Role": "Ajout rôle",
    "Supprimer Role": "Suppression rôle",
    "Liste Clients": "Liste clients",
    "Modifier Client": "Modification client",
    "Supprimer Client": "Suppression client",
    "Liste Produits": "Liste produits",
    "Ajouter Produit": "Ajout produit",
    "Modifier Produit": "Modification produit",
    "Supprimer Produit": "Suppression produit",
    "Suivi Routing": "Suivi routings",
    "Zonning": "Zoning",
    "roles affectée affectés avec succès": "Modification terminée avec succès",
    "role ajouté avec Succès": "Ajout effectué avec succès",
    "Parametrages": "Paramétrages",
    "User modifiée avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer cette utilisateur!": "Voulez-vous vraiment supprimer cet élément?",
    "Vous ne pouvez pas supprimer cette utilisateur": "Erreur vous ne pouvez pas supprimer cet élément!",
    "Attention!": "Attention!",
    "Recherche": "Recherche",
    "Photo Bl Avoir": "Photo Bl Avoir",
    "Retour sans chargement": "Retour sans chargement",
    "Insertion Mobile Double": "Insertion Mobile Double",
    "Edition Avoir": "Edition Avoir",
    "Fiche Article": "Fiche Article",
    "Accept et Refus total chargement": "Accept et Refus total chargement",
    "Ajout commande hors client": "Ajout commande hors client",
    "Recouvrement Total": "Recouvrement Total",
    "Obligation GPS": "Obligation GPS",
    "Impression_recette": "Impression_recette",
    "Modification_mobile": "Modification_mobile",
    "Retenue": "Retenue",
    "Affichage list prix": "Affichage liste prix ",
    "Quota Commande": "Quota commandes",
    "fournisseur modifiée avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer ce fournisseur!": "Voulez-vous vraiment supprimer cet élément?",
    "fournisseur ajoutée avec Succès": "Ajout effectué avec succès",
    "fournisseur Supprimée avec Succès": "Suppression effectuée avec succès",
    "Modification avec Succès": "Modification terminée avec succès",
    "Confirmation annulation Entete": "Confirmation annulation entête",
    "Registre de Commerce": "Registre de commerce",
    "Code Postale": "Code postal",
    "Contrat": "Contrat",
    "Commande Stock": "Commande stock",
    "Ajout gratuite": "Ajout gratuités",
    "Societe modifiée avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer cette societe!": "Voulez-vous vraiment supprimer cet élément?",
    "voulez vous vraiment supprimer ce distributeur!": "Voulez-vous vraiment supprimer cet élément?",
    "distributeur modifiée avec succées": "Modification terminée avec succès",
    "distributeur ajoutée avec succées": "Ajout effectué avec succès",
    "distributeur supprimée avec Succée": "Suppression effectuée avec succès",
    "Disponible pour la Livraison": "Disponible pour la livraison",
    "Camion_Prevendeur modifiée avec Succès": "Modification terminée avec succès",
    "voulez vous vraiment supprimer ce Depot!": "Voulez-vous vraiment supprimer cet élément?",
    "Camion_Prevendeur ajoutée avec Succès": "Ajout effectué avec succès",
    "Camion_Prevendeur supprimée avec Succès": "Suppression effectuée avec succès",
    "Equipement": "Equipements",
    "equipement modifiée avec succées": "Modification terminée avec succès",
    "voulez vous vraiment supprimer cette equipement!": "Voulez-vous vraiment supprimer cet élément?",
    "equipement ajoutée avec succées": "Ajout effectué avec succès",
    "equipement supprimée avec succées": "Suppression effectuée avec succès",
    "trace modifiée avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer ce trace!": "Voulez-vous vraiment supprimer cet élément?",
    "trace ajoutée avec Succès": "Ajout effectué avec succès",
    "trace Supprimée avec Succès": "Suppression effectuée avec succès",
    "Affectation equipe commercial": "Affectation équipe commercial",
    "Affectation ajoutée avec Succès": "Ajout effectué avec succès",
    "Voulez vous vraiment supprimer cet Affectation!": "Voulez-vous vraiment supprimer cet élément?",
    "Affectation Supprimé avec Succès": "Suppression effectuée avec succès",
    "Vous ne pouvez pas supprimer cet Affectation": "Erreur vous ne pouvez pas supprimer cet élément! ",
    //produits 
    "Tarification": "Tarification",
    "tarif ajoutée avec Succès": "Ajout effectué avec succès",
    "voulez vous vraiment supprimer cette Tarif!": "Voulez-vous vraiment supprimer cet élément?",
    "Tarif modifié avec Succès.": "Modification terminée avec succès",
    "Tarif supprimée avec Succès": "Suppression effectuée avec succès",
    "prix_vente_public": "prix_vente_public",
    "prix_promotion": "prix_promotion",
    "Prix modifié avec succé": "Modification terminée avec succès",
    "Prix Promotionnel": "Prix promotionnel",
    "Prix Achat HT*": "Prix d’achat HT",
    "Prix Achat TTC*": "Prix d’achat TTC",
    "Soumis Remise*": "Soumis remise",
    "Soumis Retenue*": "Soumis retenue",
    "Hors Vente": "Hors vente",
    "Ordre*": "Ordre",
    "Fiche Article": "Fiche article",
    "Produit modifiée avec Succès": "Modification terminée avec succès",
    "voulez-vous vraiment supprimer cet élément !": "Voulez-vous vraiment supprimer cet élément?",
    "Warning": "Erreur",
    "Vous pouvez pas supprimer cet article": "Erreur vous ne pouvez pas supprimer cet élément!",
    "Gratuité mis à jour avec Succès": "Modification terminée avec succès",
    "Condition modifié avec Succès": "Modification terminée avec succès",
    "Condition ajouté avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer cette Condition!": "Voulez-vous vraiment supprimer cet élément?",
    "Supprimée!": "Suppression effectuée avec succès",
    "Condition Supprimée avec Succès": "Suppression effectuée avec succès",
    "Voulez vous vraiment supprimer cette gratuite!": "Voulez-vous vraiment supprimer cet élément?",
    "gratuite supprimée avec Succès.": "Suppression effectuée avec succès",
    "Affectation Client ajouté avec Succès": "Ajout effectué avec succès",
    "gratuite supprimée avec Succès.": "Suppression effectuée avec succès",
    "Voulez vous vraiment supprimer cette promotion": "Modification terminée avec succès",
    "Promotion mis à jour avec Succès": "Modification terminée avec succès",
    "Promotion supprimée avec Succès.": "Suppression effectuée avec succès",
    "Promotion ajouté avec Succès": "Ajout effectué avec succès",
    "Modifier une Affectation Produit": "Modification terminée avec succès",
    "Affectation Produit modifié avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer cette Affectation Produit": "Voulez-vous vraiment supprimer cet élément?",
    "Affectation Produit Supprimée avec Succès": "Suppression effectuée avec succès",
    "Affectation Produit ajouté avec Succès": "Ajout effectué avec succès",
    "Affectation Produit Supprimée avec Succès": "Suppression effectuée avec succès",
    "Affectation Client ajouté avec Succès": "Ajout effectué avec succès",
    "Voulez vous vraiment supprimer cette Affectation Client!": "Voulez-vous vraiment supprimer cet élément?",
    "Supprimée!": "Suppression effectuée avec succès",
    "Affectation Client Supprimée avec Succès": "Suppression effectuée avec succès",
    "Affectation Client modifié avec Succès": "Modification terminée avec succès",
    "Modifier une Affectation Client": "Modification terminée avec succès",
    "groupe": "Groupe",
    "voulez vous vraiment supprimer ce groupe!": "Voulez-vous vraiment supprimer cet élément?",
    "groupe supprimé avec Succès.": "Suppression effectuée avec succès",
    "groupe ajouté avec Succès": "Ajout effectué avec succès",
    "affectations ajouté avec Succès": "Ajout effectué avec succès",
    "marque ajoutée avec Succès": "Ajout effectué avec succès",
    "Voulez vous vraiment supprimer cette marque!": "Voulez-vous vraiment supprimer cet élément?",
    "Gamme ajoutée avec Succès": "Ajout effectué avec succès",
    "voulez vous vraiment supprimer cette Gamme!": "Voulez-vous vraiment supprimer cet élément?",
    "Gamme supprimée avec Succès.": "Suppression effectuée avec succès",
    "Famille modifiée avec Succès": "Modification terminée avec succès",
    "voulez vous vraiment supprimer cette famille!": "Voulez-vous vraiment supprimer cet élément?",
    "Famille ajoutée avec Succès": "Ajout effectué avec succès",
    "Famille supprimée avec Succès.": "Suppression effectuée avec succès",
    "sousfamille modifiée avec Succès": "Modification terminée avec succès",
    "Voulez vous vraiment supprimer cette sousfamille!": "Voulez-vous vraiment supprimer cet élément?",
    "Sous Famille ajoutée avec Succès": "Ajout effectué avec succès",
    "sousfamille Supprimée avec Succès": "Suppression effectuée avec succès",
    //charges:
    "Ajouter une charge": "Ajout d’une charge",
    "Type Charge": "Type de charge",
    "Commerial": "Commercial",
    "Mois*": "Mois",
    "Analyse Charge": "Analyse charge",
    "Compte d'expolatation Mensuel / Van": "Compte d'exploitation mensuel / van",

    "CA Brut": "CA brut",
    "Remises": " Remises",
    "CA NET": "CA net ",
    "Achats consommés": "Achats consommés",
    "Marge Commercial Brut": "Marge commercial brute",
    "Dépences en publicité & trade": "Dépenses de publicités & trades",
    "Marge Commercial Nette": "Marge commercial nette",

    "Salaires & Charges Connexes": "Salaires et charges connexes",
    "Frais de Téléphone": "Frais de téléphone",
    "Charges dédiées à la force de vente": "Charges pour force de vente",
    "Dotation aux amortisement": "Dotation aux amortissements",
    "Charge d'interets": "Charge d’intérêts",
    "Carburant": "Carburant",
    "Entretien et réparations": "Entretien & réparations",
    "Assurance véhicule": "Assurance véhicule",
    "Tax sur les véhicules": " Taxes sur les véhicules",
    "Frais visite auto": " Frais visites auto",
    "Charges dédiées au matériel roulant": " Charges des matériaux roulants ",
    "Dotations aux amortissement": "Dotation aux amortissements ",
    "Charges dédiées au matériel informatique": "Charges du matériel informatique",
    "Fournitures consommables aux amortissement": "Fournitures consommables pour amortissements",
    "Etat TCL": "Etat TCL",
    "Droit de timbre": "Droit de timbre",
    "Autres charges dédiées": "Autres charges",
    "Total cout de distribution direct": "Total coût de distribution",
    "Depences": "Dépenses",
    "Résultat d'exploitation": "Résultat d’exploitation",
    "Nv Objectifs": "Nv objectifs",
    "N° Obj": "N° object",
    "Mois": "Mois",
    "Semaine": "Semaine",
    "SousFamille": "Sous Famille",
    "Tx de vente": "Tx de vente",
    "DN stable": "DN stable",
    "Recrutement": "Recrutement",
    "Valider les Objectifs": "Validation des objectifs",
    "Fidelite modifié avec Succès": "Modification terminée avec succès",
    "Condition modifié avec Succès": "Modification terminée avec succès",
    "voulez vous vraiment supprimer cette Condition!": "Voulez-vous vraiment supprimer cet élément?",
    "Condition supprimée avec Succès.": "Suppression effectuée avec succès",
    "Ajouter une Condition": "Ajout effectué avec succès",
    "Condition ajouté avec Succès": "Ajout effectué avec succès",
    //STOCK
    "Inventaire": "Inventaire",
    "Correction effectué avec succé": "Correction effectuée avec succès",
    "Depot": "Dépôt",
    "Ajouter Inventaire": "Ajout inventaire",
    "Emplacement *": "Emplacement",
    "Ecart": "Ecart",
    "Inventaire ajoutée succés": "Ajout effectué avec succès",
    "Il existe déja un inventaire non cloturé": "Il existe déjà un inventaire non clôturé",
    "Valider l'inventaire de": "Validation d’inventaire du:",
    "chargement ajoutée avec Succès": "Ajout effectué avec succès",
    "Details Inventaire": "Détails inventaire",
    "Inventaire validé avec succé": "Succès de validation",
    "Stock Camion": "Stock camion",
    "Todolist": "Todolist",
    "Rapport visites": "Rapport visites",
    "Réalisation": "Réalisation",
    "Réalisation en Valeur": "Réalisation en valeur",
    "Réalisation en quantité": "Réalisation en quantité",
    "Réalisation en Valeur": "Réalisation en valeur",
    "Réalisation en quantité": "Réalisation en quantité",
    "Valeur": "Valeur",
    "Quantité": "Quantité",
    "DN": "DN",
    "Recrutement": "Recrutement",
    "Recouvrement": "Recouvrement",

    //Conventions
    "Produits": "Produits",
    "Choisir Gamme": "Choisir gamme",
    "Choisir Famille": "Choisir famille",
    "Choisir Sousfamille": "Choisir sous famille",
    "Choisir Marque": "Choisir marque",
    "Interval": "Intervalle",
    "Assortiment par Categorie Client": "Assortiment par catégorie client",
    "Veuillez bien selectionner au moins une categorie": "Veuillez sélectionner au moins une catégorie ",
    "Conventions ajoutée avec succée": "Ajout effectué avec succès",
    "_rapport1_" : '*Rapport 1 : Total vente commercial par produit ( chiffre/qté/carton ) à une intervalle de temps limitée par une date de début et de fin , les modes de paiement( Esp , Chq , crédit ) le taux de vente et de succés, le nombre de clients visités et le nombre de nouveaux clients recruté .',
    "_rapport2_" : '*Rapport 2 : Total vente pour chaque commercial ( chiffre/qte/carton ) à une intervalle de temps limitée par une date de début et de fin , les modes de paiement( Esp , CHQ , crédit ) le taux de vente et de succés , le nombre de clients visités et le nombre de nouveaux clients recruté .',
    "_rapport3_" : '',
    "_rapport4_" : '*Rapport 4 : KPI commercial ( ventes, crédit, recouvrement, nbr BL, nbr visites ... ) par jour , par mois et par année, details des tournées ( heure debut , heure fin , temps des travaux et temps hors travaux ... ) quotidien. clients programmés non visités et vente par produits' ,
    "_rapport5_" : '*Rapport 5 : Vente par marque , gamme et famille ',
    "_rapport6_" : '*Rapport 6 : Vente et DN par région',
    "_rapport7_" : '*Rapport 7 : Vente periodique ( date debut, date fin ) par marque, famille, sous famille, gamme, produit et fournisseur ( carton , qte , valeur ) .',
    "_rapport8_" : '*Rapport 8 : Vente periodique: par mois, année d\'une famille par commercial .',
    "_rapport9_" : '*Rapport 9 : Vente famille ( qte, chiffre, pourcentage ) par gamme de produit.',
    "_rapport10_" : '*Rapport 10 : Vente clients par date.',
    "_rapport11_" : '*Rapport 11 : Pourcentage vente de chaque commercial pour chaque gamme de produit.',
    "_rapport12_" : '*Rapport 12 : Vente par sous famille par client.',
    "_rapport13_" : '*Rapport 13 : DN par produit, le pourcentage est calculé ainsi:( nbre de produit vendus par mois / nbre clients par mois ) ',
    "_rapport14_" : '*Rapport 14 : DN par produit, le pourcentage est calculé ainsi:( nbr de produit vendus par mois / nbr clients par mois )par région ',
    "_rapport15_" : '*Rapport 15 : (taux succès/taux de respect) pour chaque commercial ',
    "_rapport16_" : '',
    "_rapport17_" : '*Rapport 17 : Vente commercial: affiche le total des ventes en indiquant la date début et fin. ',
    "_rapport18_" : '*Rapport 18 : Vente commercial: ( nbre de visites, nbre de Bl, nbre des BL payés et impayés, nbre des avoirs, des ventes, recettes, recouvrements ) ... ',
    "_rapport19_" : '*Rapport 19 : KPI commande par gouvernerat.',
    "_rapport20_" : '*Rapport 20 : Chiffre de vente par zone.',
    "_rapport21_" : '*Rapport 21 : Vente total , nombre des BL total , nbre de visites total et qte total vendues et la classification par ordre croissant selon le montant de vente par ( gamme , famille , sous famille , marque et produit )',
    "_rapport22_" : '*Rapport 22 : Vente par client ( gamme , famille , sous famille , marque ) par commercial et date.',
    "_rapport23_" : '*Rapport 23 : Credit par commercial en affichant les coordonnées du client, la date de crédit et la route.',
    "_rapport24_" : '*Rapport 24 : Consultation des crédits des clients',
    "_rapport25_" : '*Rapport 25 : Credit par commercial en affichant les coordonnées des clients, la date de crédit et le total.',
    "_rapport26_" : '',
    "_rapport27_" : '',
    "_rapport28_" : '',
    "_rapport29_" : '',
    "_rapport30_" : '',
    "_rapport31_" : '',
    "_rapport32_" : '',
    "_rapport33_" : '',
    "_rapport34_" : '',
    "_rapport35_" : '',
    "_rapport36_" : '',
    "_rapport37_" : '',
    "_rapport38_" : '',
    "_rapport39_" : '',
    "_rapport40_" : '',

     // DASHBOARD https://demo.clediss.online/
    "Obejectif Journalier": "Objectif journalier",
    "Vente par Commercial/Gammes": "Vente par Commercial/Gammes",
    "Vente par Commercial/Marques": "Vente par Commercial/Marques",
    // BILAN JOURNALIER https://demo.clediss.online/chargements/stat-chargements
    "Etat Caisse": "Etat Caisse",
    "Retour hier": "Retour hier",
    "RET/C": "RET/C",
    "Conforme": "Conforme",
    "Code X3": "Code X3",
    'Ratio' : "Ratio",
    "CHARG/C": "CHARGE/C",
    "VENTE/P": "VENTE/P",
    "VENTE/C": "VENTE/C",
    "CA/CASSE": "CA/CASSE",
    "REM": "REM",
    "CA/DLC": "CA/DLC",
    "ECART MOIS": "ECART MOIS",
    "Crédit gms & Gazerne": "Crédit gms & Gazerne",
    "STE" : "STE",
    "CA/GRATUITE": "CA/GRATUITE",
    "التنظيم & crédit": "Ordre et crédit",
    "Gazoil": "Gazoil",
    "Entretien": "Entretien",
    "Carburant": "Carburant",
    "ESP Divers": "ESP Divers",
    "Depense": "Depense",
    "Commentaire": "Commentaire",
    //https://demo.clediss.online/caisses/
    "Crée le": "Crée le",
    //https://demo.clediss.online/chargements/stat-vente
    "Stat Vente": "Stat Vente",
    "Vente Réelle - Gratuite - Vente Facture CL": "Vente Réelle - Gratuite - Vente Facture CL",
    "Vente Facture CL": "Vente Facture CL",
    "Gratuite": "Gratuite",
    "Dlc + Casse": "Dlc + Casse",
    "Excel Vente": "Excel Vente",
    "Export factures CL": "Export factures CL",
    "Export factures Passager": "Export factures Passager",
    //https://demo.clediss.online/chargements/stat-vente-commercials
    "Excel Vente": "Excel Vente",
    "Export factures CL": "Export factures CL",
    "Export factures Passager": "Export factures Passager",
    //https://demo.clediss.online/chargements/
    "Valeur Stock": "Valeur Stock",
    "Total Caisse": "Total Caisse",
    //https://demo.clediss.online/chargements/liste
    "Date chargement": "Date chargement",
    //https://demo.clediss.online/clients/ajouter
    "Adresse de Livraison": "Adresse de Livraison",
    "Autorisation cadeaux": "Autorisation cadeaux",
    //https://demo.clediss.online/clients/parametre
    "Parametrage": "Parametrage",
    "Activites": "Activites",
    "Categories": "categories",
    "Code": "Code",
    "Libelle": "Libelle",
    "Action": "Action",
    "Ajouter activite": "Ajouter activite",
    "Code": "Code",
    "Libelle": "Libelle",
    "Action": "Action",
    "Ajouter categorie": "Ajouter categorie",
    "Libelle": "Libelle",
    "Tarification": "Tarification",
    "Ajouter": "Ajouter",
    //https://demo.clediss.online/cloturebls/    
    "Cloture bls": "Cloture bls",
    //https://demo.clediss.online/cadeaux/
    "Ajouter Cadeaux": "Ajouter Cadeaux",
    //https://demo.clediss.online/commandes///
    "Grossiste": "Grossiste",
    // "Validé": "Validé",
    // "Non Validé": "Non Validé",
    //https://demo.clediss.online/plandecharges///
    "Plan de charge": "Plan de charge",
    //https://demo.clediss.online/visites/
    "Clients": "Clients",
    "Visites": "Visites",
    //https://demo.clediss.online/routings/
    "Tournees & Layers Map": "Tournees & Layers Map",
    //https://demo.clediss.online/users/list
    "Configuration des Emails automatiques": "Configuration des Emails automatiques",
    "Envoyer les rapports journalier à ": "Envoyer les rapports journalier à",
    "Envoyer les commandes à": "Envoyer les commandes à",
    "Envoyer les Bls à": "Envoyer les Bls à",
    "Emails": "Emails",
    //https://demo.clediss.online/depots/edit/
    "Plafond dc": "Plafond dc",
    "Modification Séquences": "Modification Séquences",
    //https://demo.clediss.online/equipements/
    "Equipement": "Equipement",
    //https://demo.clediss.online/users/affectationsCommercialCamion
    "Affectation equipe commercial": "Affectation equipe commercial",
    //https://demo.clediss.online/produits/
    "Augmentation prix": "Augmentation prix",
    //https://demo.clediss.online/produits/ajouter
    "Prix Achat HT*": "Prix Achat HT",
    "Prix Achat TTC*": "Prix Achat TT",
    "Soumis R*": "Soumis R",
    "Soumis Retenue": "Soumis Retenue",
    "Hors Vente": "Hors Vente",
    //https://demo.clediss.online/rapports/
    "suivi vente client par type": "suivi vente client par type",
    "Vente S/F/promo/gratuite": "Vente S/F/promo/gratuite",
    "Reporting Commercial": "Reporting Commercial",
    "Reporting Visites/Users": "Reporting Visites/Users",
    "Reporting Visites/Dates": "Reporting Visites/Dates",
    "suivi Echantillons": "suivi Echantillons",
    "Tournées/Region": "Tournées/Region",
    "Assortiments Relevé": "Assortiments Relevé",
    "Commercial / Client": "Commercial / Client",
    "Cause non vente": "Cause non vente",
    "Gratuites & accessoires": "Gratuites & accessoires",
    "Tickets": "Tickets",
    "Vente Hebdomadaire": "Vente Hebdomadaire",
    //https://demo.clediss.online/charges/
    "Type Charge": "Type Charge",
    "Commerial": "Commerial",
    "Ajouter une charge": "Ajouter une charge",
    "Analyse": "Analyse",
    "Type Charge": "Type Charge",
    //https://demo.clediss.online/charges/analyses
    "Analyse Charge": "Analyse Charge",
    "Compte d expolatation Mensuel / Van": "Compte d expolatation Mensuel / Van",
    "CA Brut": "CA Brut",
    "Remises": "Remises",
    "CA NET": "CA NET",
    "Achats consommés": "Achats consommés",
    "Marge Commercial Brut": "Marge Commercial Brut",
    "Dépences en publicité & trade": "Dépences en publicité & trade",
    "Marge Commercial Nette": "Marge Commercial Nette",
    "Salaires & Charges Connexes": "Salaires & Charges Connexes",
    "Frais de Téléphone": "Frais de Téléphone",
    "Charges dédiées à la force de vente": "Charges dédiées à la force de vente",
    "Dotation aux amortisement": "Dotation aux amortisement",
    "Charge d interets": "Charge d interets",
    "Carburant": "Carburant",
    "Entretien et réparations": "Entretien et réparations",
    "Assurance véhicule": "Assurance véhicule",
    "Tax sur les véhicules": "Tax sur les véhicules",
    "Frais visite auto": "Frais visite auto",
    "Charges dédiées au matériel roulant": "Charges dédiées au matériel roulant",
    "Dotations aux amortissement": "Dotations aux amortissement",
    "Charges dédiées au matériel informatique": "Charges dédiées au matériel informatique",
    "Fournitures consommables aux amortissement": "Fournitures consommables aux amortissement",
    "Etat TCL": "Etat TCL",
    "Droit de timbre": "Droit de timbre",
    "Autres charges dédiées": "Autres charges dédiées",
    "Total cout de distribution direct": "Total cout de distribution direct",
    "Résultat d exploitation": "Résultat d exploitation",
    //https://demo.clediss.online/depences/
    "depences": "depences",
    //https://demo.clediss.online/objectifs/
    "Objectif Annuel": "Objectif Annuel",
    "Objectif Annuel/Region": "Objectif Annuel/Region",
    "Objectif Annuel/User": "Objectif Annuel/User",
    "Ajouter des objectifs Journalier": "Ajouter des objectifs Journalier",
    "objectifs Journalier/Réalisation": "objectifs Journalier/Réalisation",
    "Date début": "Date début",
    "Date fin": "Date fin",
    "Recalculer les objectifs": "Recalculer les objectifs",
    "Recalculer les objectifs de": "Recalculer les objectifs de",
    "Selectionner tout": "Selectionner tout",
    "Valider les objectifs": "Valider les objectifs",
    "Objectif S": "Objectif S",
    "Coef": "Coef",
    //https://demo.clediss.online/mouvements/
    "Sous Societe": "Sous Societe",
    //https://demo.clediss.online/stockDepot/
    "Transferer": "Transferer",
    //https://demo.clediss.online/achat/
    "Trier par": "Trier par",
    "Code Paiement": "Code Paiement",
    "Date Saisie": "Date Saisie",
    "Date Facture": "Date Facture",
    "Document": "Document",
    "Brut": "Brut",
    "HT": "HT",
    "TTC": "TTC",
    "Code Paiement": "Code Paiement",
    "Reglements": "Reglements",
    "Statistique": "Statistique",
    "Mouvement Fournisseurs": "Mouvement Fournisseurs",
    "Chéquier": "Chéquier",
    //https://demo.clediss.online/achat/reglements
    "Choisir": "Choisir",
    "Libelle": "Libelle",
    "Type": "Type",
    "Montant": "Montant",
    "Reste": "Reste",
    "N°": "N°",
    "Dates": "Dates",
    "Banque": "Banque",
    "Commentaire": "Commentaire",
    "Total": "Total",
    "Date Début": "Date Début",
    "Date Fin": "Date Fin",
    "Trier par": "Trier par",
    "Code Paiement": "Code Paiement",
    "Info": "Info",
    "Document": "Document",
    "Brut": "Brut",
    "HT": "HT",
    "TTC": "TTC",
    "Net a payer": "Net a payer",
    "Code Paiement": "Code Paiement",
    "Statistique": "Statistique",
    "Mouvement Fournisseurs": "Mouvement Fournisseurs",
    "Chéquier": "Chéquier ",
    //https://demo.clediss.online/achat/statachat
    "Achat par mois": "Achat par mois",
    "Encours par mois": "Encours par mois",
    "Chiffre HT Net": "Chiffre HT Net",
    "N° Pièce": "N° Pièce",
    "Total HT Brut": "Total HT Brut",
    "Total HT Net": "Total HT Net",
    //https://demo.clediss.online/mouvement-fournisseurs/
    "Mouvement Fournisseurs": "Mouvement Fournisseurs",
    "NB/Facture": "NB/Facture",
    "Date Dernier Livraison": "Date Dernier Livraison",
    "Reference Facture": "Reference Facture",
    "Disitributeur": "Disitributeur",
    "Réf Facture": "Réf Facture",
    "Ref Facture": "Ref Facture",
    //https://demo.clediss.online/caisses/
    "date Modif": "date Modif",
    //https://demo.clediss.online/notes/
    "Theme": "Theme",
    "Vocal": "Vocal",
    //https://demo.clediss.online/realisation/
    "Valeur": "Valeur",
    "Quantité": "Quantité",
    "DN": "DN",
    "Recrutement": "Recrutement",
   
    "Valeur": "Valeur",
    "Quantite": "Quantite",
    "Commercial": "Commercial",
    "Objectif": "Objectif",
    "Réalisé": "Réalisé",
    "Réalisation": "Réalisation",
    //https://demo.clediss.online/assortiments/
    "Assortiment par Categorie": "Assortiment par Categorie",
    //https://demo.clediss.online/recensement/
    "Traveaux merchs": "Traveaux merchs",
    "Delegations & DN": "Delegations & DN",
    "Agent": "Agent",
    "Fermé": "Fermé",
	
	
	// DASHBORD
	"Objectif Journalier":"Objectif Journalier",
	"Chiffre":"Chiffre",
	"Quantite":"Quantite",
	"Colisage":"Colisage",
	"Poids":"Poids",
	//https://demo.clediss.online/chargements/stat-chargements
	"Interfaçage":"Interfaçage",
	"Comission/Marge":"Comission/Marge",
	"Stat Retour":"Stat Retour",
	"J.commercial":"J.commercial",
	"Correction Prix chargement":"Correction Prix chargement",
	"Par date":"Par date",
	"Export Par date": "Export Par date",
	//https://demo.clediss.online/chargements/
	"Retour instantané":"Retour instantané",
	"N° Lot":"N° Lot",
	//https://demo.clediss.online/clients/ajouter
	"Categorie Statistique":"Categorie Statistique",
	//https://demo.clediss.online/clients/parametre
	"Tarification":"Tarification",
	//https://demo.clediss.online/devis-list///
	"Devis":"Devis",
	//https://demo.clediss.online/visites/
	"Position client invalide":"Position client invalide",
	"Position visite invalide": "Position visite invalide",
	//https://demo.clediss.online/routings/
	"Tournees & Layers Maps":"Tournees & Layers Maps",
	//://demo.clediss.online/parametrages/
	"Validation Bl* (ex: 72 H,24 H)":"Validation Bl* (ex: 72 H,24 H)",
	"Validation cadeaux":"Validation cadeaux",
	"commentaire commande":"commentaire commande",
	"Affichage produit hors_stock":"Affichage produit hors_stock",
	"Proposition commande bl":"Proposition commande bl",
	"Paiement android":"aiement android",
	"Impression decharge client":"Impression decharge client",
	"Email_recap":"Email_recap",
	//https://demo.clediss.online/produits/
	"Télécharger le model d'import (vide)":"Télécharger le model d'import (vide)",
//	https://demo.clediss.online/rapports/
	"Chargement/retour":"Chargement/retour",
	"Taux demande CHargement":"Taux demande CHargement",
	"Suivi Prospect":"Suivi Prospect",
	"Prime CASH/TERME":"Prime CASH/TERME",
//	https://demo.clediss.online/charges/analyses
	"Compte d'expolatation Mensuel / Van":"Compte d'expolatation Mensuel / Van",
	"CA Brut":"CA Brut",
	"Remises":"Remises",
	"CA NET":"CA NET",
	"Achats consommés":"Achats consommés",
	"Marge Commercial Brut":"Marge Commercial Brut",
	"Dépences en publicité & trade":"Dépences en publicité & trade",
	"Marge Commercial Nette":"Marge Commercial Nette",
	"Salaires & Charges Connexes":"Salaires & Charges Connexes",
	"Frais de Téléphone":"Frais de Téléphone",
	"Charges dédiées à la force de vente":"Charges dédiées à la force de vente",
	"Dotation aux amortisement":"Dotation aux amortisement",
	"Charge d'interets":"Charge d'interets",
	"Carburant":"Carburant",
	"Entretien et réparations":"Entretien et réparations",
	"Assurance véhicule":"Assurance véhicule",
	"Tax sur les véhicules":"Tax sur les véhicules",
	"Frais visite auto":"Frais visite auto",
	"Charges dédiées au matériel roulant":"Charges dédiées au matériel roulant",
	"Dotations aux amortissement":"Dotations aux amortissement",
	"Charges dédiées au matériel informatique":"Charges dédiées au matériel informatique",
	"Fournitures consommables aux amortissement":"Fournitures consommables aux amortissement",
	"Etat TCL":"Etat TCL",
	"Droit de timbre":"Droit de timbre",
	"Autres charges dédiées":"Autres charges dédiées",
	"Total cout de distribution direct":"Total cout de distribution direct",
	"Résultat d'exploitation":"Résultat d'exploitation",
//	https://demo.clediss.online/charges/types
	"Type Charge":"Type Charge",
	"Code":"Code",
	"Libelle":"Libelle",
	"Centre Cout":"Centre Cout",
	"Nombre de mois":"Nombre de mois",
	"Action":"Action",
	"Ajouter type charge":"Ajouter type charge",
	"Nombre de mois":"Nombre de mois",
//	https://demo.clediss.online/objectifs/propositions
	"Propositions":"Propositions",
	"Date Début":"Date Début",
	"Date Fin":"Date Fin",
	"Type":"Type",
	"Commercial":"Commercial",
	"Client":"Client",
	"Ordre":"Ordre",
	"Code":"Code",
	"Produit":"Produit",
	"Colisage":"Colisage",
	"Prix HT":"Prix HT",
	"Prix TTC":"Prix TTC",
	"Nbr Jours(vente)":"Nbr Jours(vente)",
	"Total Quantite":"Total Quantite",
	"Total Chiffre":"Total Chiffre",
	"Total Poids":"Total Poids",
	"Quantite":"Quantite",
	"Box":"Box",
	"Chiffre":"Chiffre",
	"Poids":"Poids",
//	https://demo.clediss.online/stockDepot/historique-stocks-depots-par-inventaire
	"Date Hier (StockHier)":"Date Hier (StockHier)",
	"Date du dernier Stock (Stock Finale)":"Date du dernier Stock (Stock Finale)",
	"Ordre":"Ordre",
	"Code":"Code",
	"Produit":"Produit",
	"Caisse":"Caisse",
	"Stock Hier":"Stock hier",
	"Nbr Caisse":"Nbr Caisse",
	"Unite/CV":"Unite/CV",
	"Stock AV Chargement":"Stock AV Chargement",
	"Stock APR Chargement":"Stock APR Chargement",
	//https://demo.clediss.online/caisses/
	"cloturé le":"cloturé le",
	"Échange GMS":"Échange GMS",
//	https://demo.clediss.online/stocks/
	"Aide Commercial":"Aide Commercial",
//	https://demo.clediss.online/realisation/
	"Valeur":"Valeur",
	"Quantité":"Quantité",
	"DN":"DN",
	"Recrutement":"Recrutement",
	"Recouvrement":"Recouvrement",
//	https://demo.clediss.online/tickets/
	"Résolu":"Résolu",
	"commentaire":"commentaire",
	
	

};