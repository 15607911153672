function recensementController($uibModal, $rootScope, $scope, WS, $window, $compile ) {
    'ngInject';
    const vm = this;
    vm.activites = {};
    vm.new_tournee = {};
    vm.per_page = '50';
    vm.mapstunisie_latitude = '34.770981';
    vm.mapstunisie_longitude = '10.702299';
    vm.search_tournee = {};
    vm.search_tournee.per_page="50";
    vm.selected_tournees = [];
    vm.searchbox = {};
    vm.stock_all_layers = [];
    vm.show_dn = false;
    vm.layers_json = [];
    vm.layers_params = [];


    $($window).scroll(function (e) {
        var $el = $('#map_delegations_content');
        if($('#stopMapOn')){
            var stopOn = $('#stopMapOn').offset().top;

            var isPositionFixed = ($el.css('position') == 'fixed');
            if ($(this).scrollTop() > 200 && $(this).scrollTop() < stopOn && !isPositionFixed) {
                $el.css({ 'position': 'fixed', 'top': '40px', 'width' : '47%'  });
            }
            if (($(this).scrollTop() < 200)) {
                $el.css({ 'position': 'relative', 'top': '0px', 'width' : '100%' });
            }
        }
        
    });


    vm.getInfoCommercial = function (code) {
        vm.infouser = _.findWhere(vm.selectusers, { code: code });
        //console.log(vm.infouser);
    }

    vm.selectUsers = function () {
        WS.getData('users/selectUsers' )
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getLocalites = function () {
        WS.get('localites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.localites = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDelegations = function () {
        WS.getData('resultats_merchs/selectDelegations', {gouvernorat_code : vm.searchbox.gouvernorat_code})
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getDelegationsByMGouvs = function () {
        WS.post('resultats_merchs/getDelegationsByMGouvs', {gouvernorat_code : vm.searchbox.gouvernorat_code})
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectGouvernorats = () => {
        
        WS.getData('resultats_merchs/selectGouvernorats')
            .then(function(response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.selectLocalites = () => {
        WS.getData('resultats_merchs/selectLocalites', {gouvernorat_code : vm.searchbox.gouvernorat_code, delegation_code : vm.searchbox.delegation_code})
            .then(function(response) {
                if (response.status == 200) {
                    vm.localites = response.data;
                    
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.selectLocalitesMulti = () => {
        WS.post('resultats_merchs/selectLocalitesMulti', {gouvernorat_code : vm.searchbox.gouvernorat_code, delegation_code : vm.searchbox.delegation_code})
            .then(function(response) {
                if (response.status == 200) {
                    vm.localites = response.data;
                    
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.selectDelegationLocalite = function () {
        WS.getData('resultats_merchs/selectDelegationLocalite',  {gouvernorat_code : vm.searchbox.gouvernorat_code, delegation_code : vm.searchbox.delegation_code})
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations_localite = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDelegationLocaliteMulti = function () {
        WS.post('resultats_merchs/selectDelegationLocaliteMulti',  {gouvernorat_code : vm.searchbox.gouvernorat_code, delegation_code : vm.searchbox.delegation_code})
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations_localite = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.clientsByDelegationActivite = function () {
        WS.getData('resultats_merchs/clientsByDelegationActivite',  
                {
                    gouvernorat_code : vm.searchbox.gouvernorat_code, 
                    delegation_code : vm.searchbox.delegation_code,
                    application : vm.searchbox.application
                })
            .then(function (response) {
                if (response.status == 200) {
                    vm.cls_delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.clientsByDelegationActiviteMulti = function () {
        WS.post('resultats_merchs/clientsByDelegationActiviteMulti',  
                {
                    gouvernorat_code : vm.searchbox.gouvernorat_code, 
                    delegation_code : vm.searchbox.delegation_code,
                    application : vm.searchbox.application
                })
            .then(function (response) {
                if (response.status == 200) {
                    vm.cls_delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.clientsByLocaliteActivite = function () {
        WS.getData('resultats_merchs/clientsByLocaliteActivite', {
            gouvernorat_code : vm.searchbox.gouvernorat_code, 
            delegation_code : vm.searchbox.delegation_code, 
            localite_code : vm.searchbox.localite_code,
            application : vm.searchbox.application
        })
            .then(function (response) {
                if (response.status == 200) {
                    vm.cls_localites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.clientsByLocaliteActiviteMulti = function () {
        WS.post('resultats_merchs/clientsByLocaliteActiviteMulti', {
            gouvernorat_code : vm.searchbox.gouvernorat_code, 
            delegation_code : vm.searchbox.delegation_code, 
            localite_code : vm.searchbox.localite_code,
            application : vm.searchbox.application
        })
            .then(function (response) {
                if (response.status == 200) {
                    vm.cls_localites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    


    /** */
    vm.selectTourneesBySearch = (page = 1, per_page=50) => {
        vm.search_tournee.page = page;
        vm.search_tournee.per_page = per_page;
        WS.getData('resultats_merchs/selectTourneesBySearch', vm.search_tournee)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selected_tournees = response.data.data;

                    $scope.$apply();
                    if(vm.selected_tournees.length>0){
                         $('#map_add_tournees').empty();
                         $('#map_add_tournees').append('<div style="width: 100%; height: 550px" id="map_add_tournees_content"></div>');
                        vm.initMapsAddTournees(true);
                        console.log('reinisialisation : initMapsAddTournees')
                    }
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateCouleurUser = (user) => {
        WS.post('users/updateCouleurUser', user )
        .then(function (response) {
            if (response.status == 200) {
                //vm.selectusers = response.data;
                swal(
                    'Succès',
                    'Couleur modifiée avec succès',
                    'success'
                );
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.removeTournee = (value, key) => {
        
       vm.map.eachLayer(function (layer) {
            if(value.id == layer.options.id){
                vm.map.removeLayer(layer)
            }
           //
        }); 
    }
    vm.clearLayers = () => {
        _.each(vm.stock_all_layers, layer => {
            vm.map.removeLayer(layer)
        });
        //reinitialise draw for create new tournee
        vm.drawnItems = L.geoJson(null, vm.paramsdrawitem).addTo(vm.map);
        setTimeout(function () {
            vm.map.invalidateSize();
            console.log('clearLayers initialized');
        }, 2000);
    }

    vm.appendLayers = () => {
        vm.clearLayers();

        vm.stock_all_layers = [];
        vm.layers_json = [];
        vm.layers_params = [];

        _.each(vm.selected_tournees, (element, index) => {
            vm.paramsdrawitem.id = element.id;
            vm.paramsdrawitem.code = element.code;
            vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
            vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
                layer.bindPopup('<pre>'+ element.id + ' - '+ element.user +' - '+ element.date_debut + ' - '+ element.date_fin +'</pre>');
                layer._leaflet_id = element.id;
            }
            vm.current_layer = JSON.parse(element.layer);
            vm.current_layer._leaflet_id = element.id;
            vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem, element.type_layer, element.id).addTo(vm.map);
            vm.stock_all_layers.push(vm.drawnItems);
            vm.layers_json.push(vm.current_layer);
            vm.layers_params.push(vm.paramsdrawitem);
            // vm.drawnItems.eachLayer(function (layer) {
            //     layer._leaflet_id =  element.id;
            // });
            //console.log('Polygon : ', element.id, vm.drawnItems)
            if(index==0){
                vm.bounds = L.latLngBounds(vm.drawnItems.getBounds());
            }else{
                vm.bounds.extend(vm.drawnItems.getBounds());
            }
        })
        vm.map.fitBounds(vm.bounds);
        //reinitialise draw for create new tournee
        vm.drawnItems = L.geoJson(null, vm.paramsdrawitem).addTo(vm.map);
    }

    vm.toggleStreet = (element, key, toggle) => {

        vm.clearLayers();

        vm.stock_all_layers = [];
        vm.layers_json = [];
        vm.layers_params = [];

        vm.paramsdrawitem.id = element.id;
        vm.paramsdrawitem.code = element.code;
        vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
        vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
            layer.bindPopup('<pre>'+ element.id + ' - '+ element.user +' - '+ element.date_debut + ' - '+ element.date_fin +'</pre>');
            layer._leaflet_id = element.id;
        }
        vm.current_layer = JSON.parse(element.layer);
        vm.current_layer._leaflet_id = element.id;
        vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem, element.type_layer, element.id).addTo(vm.map);
        vm.stock_all_layers.push(vm.drawnItems);
        vm.layers_json.push(vm.current_layer);
        vm.layers_params.push(vm.paramsdrawitem);

        vm.map.fitBounds(vm.drawnItems.getBounds());
        
        // Get street of commercial
        WS.getData('trackings/getStreetByUser', element)
            .then(function (response) {
                if (response.status == 200) {
                    vm.polyline = [];
                    if(response.data.length > 0){
                        _.each(response.data, position => {
                            vm.polyline.push([
                                position.latitude, position.longitude
                            ]);
                        })
                        console.log(vm.polyline);

                        //  drow result of polyline            
                        vm.paramsdrawitem.id = 'path_'+element.id;
                        vm.paramsdrawitem.code = 'path_'+element.code;
                        vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.id + ' - '+ element.user +' - '+ element.date_debut + ' - '+ element.date_fin +'</pre>');
                            layer._leaflet_id = 'path_'+element.id;
                        }
                        vm.current_layer = {};
                        vm.current_layer.type = 'Feature';
                        vm.current_layer.geometry = {
                            "type":"Path",
                            "coordinates": [vm.polyline]
                        };
                        vm.current_layer._leaflet_id = 'path_'+element.id;

                        vm.drawnItems = L.polyline(vm.polyline, {color: 'red'}).addTo(vm.map);
                        vm.stock_all_layers.push(vm.drawnItems);
                        vm.layers_json.push(vm.current_layer);
                        vm.layers_params.push(vm.paramsdrawitem);
                
                        //zoom
                        _.each(vm.stock_all_layers, (element, index) => {
                            if(index==0){
                                vm.bounds = L.latLngBounds(element.getBounds());
                            }else{
                                vm.bounds.extend(element.getBounds());
                            }
                        })
                        vm.map.fitBounds(vm.bounds);


                        //drow two position start and stop
                        //// start
                        vm.position_start = vm.polyline[0];
                        vm.time_start = response.data[0].created_at;
                        console.log(vm.position_start, vm.time_start);
                        const fontAwesomeIconStart = L.divIcon({
                            html: '<i class="fa fa-play fa-2x"></i>',
                            iconSize: [15, 15],
                            className: 'start_position'
                        });
                        
                        vm.drawnItems = L.marker(vm.position_start ,{ icon:  fontAwesomeIconStart}).addTo(vm.map)
                            .bindPopup('Début : ' + vm.time_start)
                            vm.stock_all_layers.push(vm.drawnItems);
                            vm.layers_json.push(vm.current_layer);
                            vm.layers_params.push(vm.paramsdrawitem);

                        ///// end
                        vm.position_end = vm.polyline[vm.polyline.length - 1];
                        vm.time_end = response.data[response.data.length - 1].created_at;
                        console.log(vm.position_end, vm.time_end);
                        const fontAwesomeIconEnd = L.divIcon({
                            html: '<i class="fa fa-stop-circle-o fa-2x"></i>',
                            iconSize: [15, 15],
                            className: 'end_position'
                        });
                        vm.drawnItems = L.marker(vm.position_end ,{ icon:  fontAwesomeIconEnd}).addTo(vm.map)
                        .bindPopup('Fin : ' + vm.time_end)
                        vm.stock_all_layers.push(vm.drawnItems);
                        vm.layers_json.push(vm.current_layer);
                        vm.layers_params.push(vm.paramsdrawitem);

                        

                    }
                    //reinitialise draw for create new tournee
                    vm.drawnItems = L.geoJson(null, vm.paramsdrawitem).addTo(vm.map);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    /**
     * Initialisation de maps
     */
    
    vm.initMapsAddTournees = (force_init = false) => {
        // if(force_init){
           
        // }
        if (_.isEmpty($('#map_add_tournees_content')[0])) {
            vm.center_latitude = vm.mapstunisie_latitude;
            vm.center_longitude = vm.mapstunisie_longitude;
            //  $('#map_add_tournees').empty();
            //  $('#map_add_tournees').append('<div style="width: 100%; height: 550px" id="map_add_tournees_content"></div>');
            
        
            vm.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            vm.osmUrlSat = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
            vm.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
           

            var grayscale = L.tileLayer(vm.osmUrl, {id: 'map_add_tournees_content1', tileSize: 512,maxZoom: 18, zoomOffset: -1, attribution: vm.osmAttrib}),
             streets   = L.tileLayer(vm.osmUrlSat, {id: 'map_add_tournees_content2', tileSize: 512, maxZoom: 18,zoomOffset: -1, attribution: vm.osmAttrib});

             vm.base_layers = {
                satellite : streets,
                standard : grayscale
            }
            // vm.osm = L.tileLayer(vm.osmUrl, {
            //     maxZoom: 18,
            //     attribution: vm.osmAttrib
            // });
            //console.log('from maps : ', vm.getpays)
            vm.map = new L.Map('map_add_tournees_content', {
                center: new L.LatLng(vm.center_latitude, vm.center_longitude),
                zoom: 11,
                layers: [grayscale, streets],

                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topright'
                }
            });
    
            //vm.osm.addTo(vm.map);
            L.control.layers(vm.base_layers).addTo(vm.map);
            /**
             * add polygon and panel of draw
             */
            vm.raduis = 0;
            vm.layer = {};
            vm.geojsonMarkerOptions = {
                radius: vm.raduis
            };
            //vm.drawnItems = null;

            // if (vm.layer) {
            //     if (vm.raduis) {
            //         vm.paramsdrawitem = {
            //             pointToLayer: function (feature, latlng) {
            //                 return L.circle(latlng, vm.geojsonMarkerOptions);
            //             }
            //         }
            //     } else {
            //         vm.paramsdrawitem = {};
            //     }
            // }

            /** params draw item */
            vm.paramsdrawitem = {};
            if(Object.keys(vm.layer).length === 0){
                vm.drawnItems = L.geoJson(null, vm.paramsdrawitem).addTo(vm.map);

            }else{
                vm.drawnItems = L.geoJson(JSON.parse(vm.layer), vm.paramsdrawitem).popup('Hi There!').tooltip('Hi There!').addTo(vm.map);

            }
            if(vm.selected_tournees.length > 0 && force_init==true){
               // console.log('redraw all layers : ', vm.selected_tournees)

                _.each(vm.selected_tournees, (element, index) => {
                    vm.paramsdrawitem.id = element.id;
                    vm.paramsdrawitem.code = element.code;
                    vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                    vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
                        layer.bindPopup('<pre>'+ element.id + ' - '+ element.user +' - '+ element.date_debut + ' - '+ element.date_fin +'</pre>');
                        layer._leaflet_id = element.id;
                    }
                    vm.current_layer = JSON.parse(element.layer);
                    vm.current_layer._leaflet_id = element.id;
                    vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem, element.type_layer, element.id).addTo(vm.map);
                    vm.stock_all_layers.push(vm.drawnItems);
                    vm.layers_json.push(vm.current_layer);
                    vm.layers_params.push(vm.paramsdrawitem);
                    // vm.drawnItems.eachLayer(function (layer) {
                    //     layer._leaflet_id =  element.id;
                    // });
                    //console.log('Polygon : ', element.id, vm.drawnItems)
                    if(index==0){
                        vm.bounds = L.latLngBounds(vm.drawnItems.getBounds());
                    }else{
                        vm.bounds.extend(vm.drawnItems.getBounds());
                    }
                })
                vm.map.fitBounds(vm.bounds);

            }

            vm.map.addControl(new L.Control.Draw({
                edit: force_init == false ? { featureGroup: vm.drawnItems } : false,
               // edit:  { featureGroup: vm.drawnItems } ,
                draw: {
                    polyline: false,
                    polygon: {
                        allowIntersection: false, // Restricts shapes to simple polygons
                        showArea: true,
                    },
                    rectangle: true,
                    marker: false,
                    circlemarker: false,
                    circle: false,
                }
            }));

            vm.map.on(L.Draw.Event.CREATED, function (e) {
                console.log('------- created ------------');

                vm.type = e.layerType
                vm.layer = e.layer;
                // layer.editing.enable();

                vm.drawnItems.addLayer(vm.layer);
                if (type == 'circle') {
                    vm.raduis = vm.layer.getRadius()
                } else {
                    vm.raduis = '';
                }
                vm.shape = vm.layer.toGeoJSON()
                vm.shape_for_db = vm.shape;
                //var shape_for_db = JSON.stringify(shape);

                vm.response_layer = {
                    raduis: vm.raduis,
                    type: vm.type,
                    layer: vm.shape_for_db,
                    coordinates : vm.shape_for_db.geometry.coordinates,
                    properties : vm.shape_for_db.properties
                };
                console.log(vm.response_layer);

                console.log('-------------- delete shape ---------------');

                /**
                 * add form to create new tournee
                 */
                vm.modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'add_new_tournee_form',
                    size: 'md',
                    backdrop: false,
                    windowClass : 'z-index-sec-modal',
                    controller: function($scope, $translate) {
                        $scope.message = $translate.instant("Ajouter tournee : ");
                        $scope.layer = vm.response_layer;
                        $scope.new_tournee = {};
                        $scope.selectusers = vm.selectusers;
                        $scope.storeTournee = function() {
                            var data = {
                                code : $scope.new_tournee.code,
                                user_code : $scope.new_tournee.user_code,
                                date_debut : $scope.new_tournee.date_debut,
                                date_fin : $scope.new_tournee.date_fin,
                                couleur : $scope.new_tournee.couleur,

                                raduis: $scope.layer.raduis,
                                type: $scope.layer.type,
                                layer: $scope.layer.layer,
                                coordinates : $scope.layer.coordinates,
                                properties : $scope.layer.properties
                            }

                            console.log('------------------------ fin resultat -----------------------');
                            console.log(data);
                            console.log('------------------------ fin resultat -----------------------');
                                WS.post('resultats_merchs/storeTournee', data)
                                    .then(function (response) {
                                        if (response.status == 200) {
                                            
                                            vm.modalInstance.close();
                                            swal(
                                                'Succès',
                                                'Secteur crée avec succé',
                                                'success'
                                            );
                                            $scope.$apply();
                                        } else { }
                                    })
                                    .then(null, function (error) {
                                        console.log(error);
                                    });
                        }

                        $scope.closeModal = () => {
                            vm.map.removeLayer(vm.layer);
                            vm.modalInstance.close();
                        }
                    }
                });


                console.log('-------------- deleted shape ---------------');

                // WS.post('depots/updateLayers', data)
                //     .then(function (response) {
                //         if (response.data.success == true) {
                //             swal(
                //                 'Succès',
                //                 'Secteur crée avec succé',
                //                 'success'
                //             );
                //             // .then(function () {
                //             //     $window.location.reload();
                //             // });

                //         } else if (response.data.success == false) {
                //             swal('Error', 'Oups ', 'error');
                //         }
                //     })
                //     .then(null, function (error) {
                //         console.log(error);
                //     });
                // layer.on("edit", function (event) {
                //     var content = "";
                //     if (type == "circle") {
                //         content = 'Circle with : <ul><li> Radius : ' + layer.getRadius() + '</li>' +
                //             '<li> Center : ' + layer.getLatLng() + ' </li></ul>';
                //     }
                //     else if (type === "polygon") {
                //         content = ' Polygone with : ' + layer.getLatLngs()[0].length + ' nodes';
                //     }
                //     layer.bindPopup(content).openPopup();
                // });
            });


            vm.map.on(L.Draw.Event.EDITED, function (e) {
                console.log('-------edited------------');
                var layers = e.layers;
                console.log(layers);
                layers.eachLayer(function (layer) {
                    // Do whatever else you need to. (save to db; add to map etc)
                    // console.log('geometry: ', layer.toGeoJSON().geometry);
                    // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                    //remove previous layer
                    // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                    // console.log('area: ', area);
                    // map.addLayer(layer);

                    //issue: Finish drawing process with editable feature
                    var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                    //console.log('newLayer', newLayer);
                    //newLayer.editing.enable();
                    vm.map.addLayer(newLayer);

                    var layer_type = layer.toGeoJSON().geometry.type;
                    if (layer_type == 'circle') {
                        var raduis = layer.getRadius()
                    } else {
                        var raduis = '';
                    }
                    var shape = newLayer.toGeoJSON()
                    var shape_for_db = shape;
                    // var shape_for_db = JSON.stringify(shape);
                    //console.log(layer_type, shape_for_db);
                    var data = {
                        raduis: raduis,
                        type: layer_type,
                        layer: shape_for_db
                    };
                    console.log(data);
                    // WS.post('depots/updateLayers', data)
                    //     .then(function (response) {
                    //         if (response.data.success == true) {

                    //             swal(
                    //                 'Succès',
                    //                 'Secteur crée avec succé',
                    //                 'success'
                    //             );
                    //             // .then(function () {
                    //             //     $window.location.reload();
                    //             // });
                    //             $scope.$apply();

                    //         } else if (response.data.success == false) {
                    //             swal('Error', 'Oups ', 'error');
                    //         }
                    //     })
                    //     .then(null, function (error) {
                    //         console.log(error);
                    //     });
                });
            });

            //draw:deleted	

            // vm.map.on(L.Draw.Event.DELETED, function () {

            //     console.log('delete polygonn');
            //     // WS.post('depots/updateLayers', { depot_code: depot_code })
            //     //     .then(function (response) {
            //     //         if (response.data.success == true) {
            //     //             swal(
            //     //                 'Succès',
            //     //                 'Secteur supprimé avec succé',
            //     //                 'success'
            //     //             );
                            

            //     //         } else if (response.data.success == false) {
            //     //             swal('Error', 'Oups ', 'error');
            //     //         }
            //     //     })
            //     //     .then(null, function (error) {
            //     //         console.log(error);
            //     //     });
            // });

            setTimeout(function () {
                vm.map.invalidateSize();
                console.log('initialized');
            }, 2000);
        }else{
            console.log('maps not created!!')
        }
    }


    vm.initMapsDelegations = ( ) => {
        // if(force_init){
           
        // }
        if (_.isEmpty($('#map_delegations_content')[0])) {
            vm.center_latitude = vm.mapstunisie_latitude;
            vm.center_longitude = vm.mapstunisie_longitude;
            //  $('#map_add_tournees').empty();
            //  $('#map_add_tournees').append('<div style="width: 100%; height: 550px" id="map_delegations_content"></div>');
    
            vm.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            vm.osmUrlSat = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
            vm.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
           
            var grayscale = L.tileLayer(vm.osmUrl, {id: 'map_delegations_content1', tileSize: 512,maxZoom: 18, zoomOffset: -1, attribution: vm.osmAttrib}),
                streets   = L.tileLayer(vm.osmUrlSat, {id: 'map_delegations_content2', tileSize: 512, maxZoom: 18,zoomOffset: -1, attribution: vm.osmAttrib});

             vm.base_layers = {
                satellite : streets,
                standard : grayscale
            }
            
            // vm.osm = L.tileLayer(vm.osmUrl, {
            //     maxZoom: 18,
            //     attribution: vm.osmAttrib
            // });
            //console.log('from maps : ', vm.getpays)
            vm.map = new L.Map('map_delegations_content', {
                center: new L.LatLng(vm.center_latitude, vm.center_longitude),
                zoom: 11,
                layers: [grayscale, streets],
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topright'
                }
            });
    
            //vm.osm.addTo(vm.map);
            L.control.layers(vm.base_layers).addTo(vm.map);


            setTimeout(function () {
                vm.map.invalidateSize();
                console.log('initialized');
            }, 2000);
        }else{
            console.log('maps are ready initialized!!')
        }
    }

    vm.selectRsFamilles = () => {
        WS.getData('resultats_merchs/selectRsFamilles' )
        .then(function (response) {
            if (response.status == 200) {
               vm.rs_familles = response.data;
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.selectRsMarques = () => {
        WS.getData('resultats_merchs/selectRsMarques' )
        .then(function (response) {
            if (response.status == 200) {
               vm.rs_marques = response.data;
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.selectActivites = () => {
        WS.getData('activites/selectActivites')
            .then(function (response) {
                vm.activites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectActiviteRs = () => {
        WS.getData('activites/selectActivitesRs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectStatActivites = () => {
        WS.get('activites/selectActivites')
            .then(function (response) {
                vm.stat_activites = response.data;
                vm.tab_stat_data_activites = vm.stat_activites;
                if(vm.show_dn){
                    vm.selectFamillesByMarque();
                    vm.selectStatFamillesByMarque();
                }
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectStatActivitesRs = () => {
        WS.get('activites/selectActivitesRs')
            .then(function (response) {
                vm.stat_activites = response.data;
                vm.tab_stat_data_activites = vm.stat_activites;
                if(vm.show_dn){
                    vm.selectFamillesByMarque();
                    vm.selectStatFamillesByMarque();
                }
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.selectActivitesByApplication = () => {
        if(vm.searchbox.application=='Nomadis') {
            vm.selectActivites();
            vm.selectStatActivites();
        }else{
            vm.selectActiviteRs();
            vm.selectStatActivitesRs();
        }
    };

    //
    /**
     * Get traveaux merchandiseurs
     */
    vm.getTraveauxMerch = function (page = 1, per_page = 50 ) {

        var data = {
            page, per_page,
            famille_code : vm.searchbox.famille_code,
            marque_code : vm.searchbox.marque_code,
            gouvernorat_code : vm.searchbox.gouvernorat_code,
            delegation_code : vm.searchbox.delegation_code,
            localite_code : vm.searchbox.localite_code,
            activite_code : vm.searchbox.activite_code
        }
        WS.getData('resultats_merchs/getTraveauxMerch', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.traveaux_rs = response.data.clients.data;
                    vm.rs_merchs_marques_familles = response.data.rs_merchs_marques_familles;
                    //vm.clients = response.data.clients.data;
                    vm.current_page = response.data.clients.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.clients.form;
                    vm.last_page = response.data.clients.last_page;
                    vm.next_page_url = response.data.clients.next_page_url;
                    vm.per_page = response.data.clients.per_page;
                    vm.prev_page_url = response.data.clients.prev_page_url;
                    vm.to = response.data.clients.to;
                    vm.total = response.data.clients.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }

                    

                    /**
                     * MAPS
                     */
                    vm.selectmapsclients = angular.copy(vm.traveaux_rs);
                    vm.centerroutes = angular.copy(response.data.center.latitude + ', ' + response.data.center.longitude);
                    vm.zoomRouting = 7;
                    
                    $('#container-Client').empty();
                    $('#container-Client').html('<div style="width: 100%; height: 600px" id="mapContainerClients"></div>');

                    // var mymap = L.map('mapContainerClients')
                    //     .setView([response.data.center.latitude, response.data.center.longitude], 
                    //              vm.zoomRouting);

                    

                    vm.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    vm.osmUrlSat = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
                    vm.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                   
        
                    var grayscale = L.tileLayer(vm.osmUrl, {id: 'map_add_tournees_content1', tileSize: 512,maxZoom: 18, zoomOffset: -1, attribution: vm.osmAttrib}),
                     streets   = L.tileLayer(vm.osmUrlSat, {id: 'map_add_tournees_content2', tileSize: 512, maxZoom: 18,zoomOffset: -1, attribution: vm.osmAttrib});
        
                     vm.base_layers = {
                        satellite : streets,
                        standard : grayscale
                    }

                    var mymap = new L.Map('mapContainerClients', {
                        center: new L.LatLng(response.data.center.latitude, response.data.center.longitude),
                        zoom: vm.zoomRouting,
                        layers: [grayscale, streets],
        
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                    //     foo: 'bar',
                    //     attribution: vm.osmAttrib
                    // })
                    //     .addTo(mymap);
                    L.control.layers(vm.base_layers).addTo(mymap);


                    vm.selectmapsclients.forEach(function (element, index) {
                        // Creates a red marker with the coffee icon
                        var redMarker = L.ExtraMarkers.icon({
                            icon: 'fa-home',
                            markerColor: (parseFloat(element.encours)) < 0 ? 'red' : 'blue',
                            shape: 'circle', //'circle', 'square', 'star', or 'penta'
                            prefix: 'fa'
                        });

                        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <b>Client</b> : ${element.client}</a></br>
                                              <b>Code</b> : ${element.code}</a></br>
                                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                                              <b>Activite</b> : ${element.activite}</br>
                                              
                                            </div>
                                        `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 200
                        }).setContent(info[0])
                        marker.bindPopup(popup);

                    });
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getTraveauxMerchExport = function () {
        var data = {
            famille_code : vm.searchbox.famille_code,
            marque_code : vm.searchbox.marque_code,
            gouvernorat_code : vm.searchbox.gouvernorat_code,
            delegation_code : vm.searchbox.delegation_code,
            localite_code : vm.searchbox.localite_code,
            activite_code : vm.searchbox.activite_code
        }
        WS.getData('resultats_merchs/getTraveauxMerchExport', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.traveaux_rs_export = response.data.clients;
                    vm.rs_merchs_marques_familles_export = response.data.rs_merchs_marques_familles;
                    vm.total = vm.traveaux_rs_export.length;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportTable = function(tableId) {
        
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function(s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'Recensement';

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };


    vm.updateEtatClient = (code, ferme) => {
        console.log(code, ferme);
        WS.post('resultats_merchs/updateEtatClient', {code, ferme} )
        .then(function (response) {
            if (response.status == 200) {
                //vm.selectusers = response.data;
                swal(
                    'Succès',
                    'client modifié avec succès',
                    'success'
                );
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    };

    vm.getClientBySearch = function (delegation_code = null, localite_code = null, activite_code = null) {

        var data = {
            famille_code : vm.searchbox.famille_code,
            marque_code : vm.searchbox.marque_code,
            delegation_code,
            localite_code,
            activite_code,
            application: vm.searchbox.application
        }
        WS.getData('resultats_merchs/getClientBySearch', data)
            .then(function (response) {
                if (response.status == 200) {
                    var markerArray = [];
                    vm.d_traveaux_rs = response.data.clients;
                    vm.panel_delegations = response.data.delegations;
                    vm.d_rs_merchs_marques_familles = response.data.rs_merchs_marques_familles;
                    
                    /**
                     * MAPS
                     */
                    vm.d_selectmapsclients = angular.copy(vm.d_traveaux_rs);
                    vm.d_centerroutes = angular.copy(response.data.center.latitude + ', ' + response.data.center.longitude);
                    vm.d_zoomRouting = 7;
                   // width: 100%; height: 600px; position: fixed; top: 40px; outline: none;
                   var position = $("#map_delegations_content").css("position");
                       position = position ? position : 'relative';
                   var top = $("#map_delegations_content").css("top");
                        top = top ? top : '0px';

                    var style = '';
                    if(position=='fixed'){
                        style += 'right:5px;width:47%!important;'
                    }
                   $('#container_maps_delegation').empty();

                    $('#container_maps_delegation').html(`<div style="width: 100%; height: 650px; position:${position};top:${top};${style}" id="map_delegations_content"></div>`);

                    var mymap = L.map('map_delegations_content').setView([response.data.center.latitude, response.data.center.longitude], vm.d_zoomRouting);


                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                        foo: 'bar',
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    })
                        .addTo(mymap);

                    const fontAwesomeIconStart = L.divIcon({
                        html: '<i class="fa fa-street-view fa-3x"></i>',
                        iconSize: [15, 15],
                        className: 'start_position'
                    });



                    //add delegation layer
                    _.each(vm.panel_delegations, (element, index) => {
                        vm.paramsdrawitem = {};
                        vm.paramsdrawitem.id = element.id;
                        vm.paramsdrawitem.code = element.code;
                        vm.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        vm.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        vm.current_layer = JSON.parse(element.layer);
                       // vm.current_layer._leaflet_id = element.id;
                        vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem).addTo(mymap);
                        vm.stock_all_layers.push(vm.drawnItems);
                        vm.layers_json.push(vm.current_layer);
                        vm.layers_params.push(vm.paramsdrawitem);
                        
                        
                        if(index==0){
                            vm.bounds = L.latLngBounds(vm.drawnItems.getBounds());
                        }else{
                            vm.bounds.extend(vm.drawnItems.getBounds());
                        }
                    })


                    vm.d_selectmapsclients.forEach(function (element, index) {
                        // Creates a red marker with the coffee icon

                        
                        var myFGMarker = L.FeatureGroup;
                        var marker = L.marker([element.latitude, element.longitude] ,{ icon:  fontAwesomeIconStart});

                        markerArray.push(marker);
                            marker.addTo(mymap);

                        

                        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <b>Client</b> : ${element.client}</a></br>
                                              <b>Code</b> : ${element.code}</a></br>
                                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                                              <b>Activite</b> : ${element.activite}</br>
                                              
                                            </div>
                                        `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 200
                        }).setContent(info[0])
                        marker.bindPopup(popup);

                    });

                    var group = L.featureGroup(markerArray).addTo(mymap);
                    mymap.fitBounds(group.getBounds());
                    // if(vm.bounds){
                    //     mymap.fitBounds(vm.bounds);
                    // }

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

   




    vm.hideDn = () => {
        vm.tab_stat_data_activites = vm.stat_activites;
    }

    vm.selectStatFamillesByMarque = () => {
        var data = {
            marque_code : vm.searchbox.marque_code
        }
        WS.getData('resultats_merchs/selectStatFamillesByMarque', data )
        .then(function (response) {
            if (response.status == 200) {
               //vm.rrs_familles_marque = response.data.familles;
               vm.tab_stat_data_activites = response.data.all; 
            //    _.each(vm.stat_activites, async  (element, index) => {
            //         element.type="activite";
            //         var activite_code = element.code;
            //         console.log(activite_code, element.libelle)
            //         vm.tab_stat_data_activites.push(element);
            //         _.each(vm.rrs_familles_marque, (famille, findex) => {
            //             var newobj = famille;
            //             newobj.activite_code = activite_code;
            //             vm.tab_stat_data_activites.push(newobj);

            //             //
            //        });
            //    });

               console.log(vm.tab_stat_data_activites)
               $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }


    vm.selectFamillesByMarque = () => {
        var data = {
            marque_code : vm.searchbox.marque_code
        }
        WS.getData('resultats_merchs/selectFamillesByMarque', data )
        .then(function (response) {
            if (response.status == 200) {
                vm.rs_familles_marque = response.data;
                vm.rrs_familles_marque = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }

    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }
    
    vm.getDetailsByClientFamille = function (tr) {
        var data = {
            client_code : tr.code,
            marque_code : vm.searchbox.marque_code

        }
        WS.getData('resultats_merchs/getDetailsByClientFamille', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rs_details = response.data;
                    
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.callPanelZonning = () => {
        // $emit ('method name', 'params')
        $rootScope.$emit("methodOpenModalZonning", {});
    }
}
export default {
    name: 'recensementController',
    fn: recensementController
};