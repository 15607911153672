function ticketsController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
 
    vm.tickets=  {};
    // vm.get = function() {
    //     WS.get('https://api.sav.priorityexpeditions.com/api/v1/tickets', vm.access)
    //         .then(function(response) {
    //             if (response.status == 200) {
    //                 vm.tickets = response.data;
    //                 $scope.$apply();
    //                 $('.table').DataTable({
    //                     "language": DatatableLang
    //                 });
    //             } else {}
    //         })
    //         .then(null, function(error) {
    //             console.log(error);
    //         });
    // }



    // vm.update = function(ticket) {
    //     //Sconsole.log(activite);
    //     WS.put('https://api.sav.priorityexpeditions.com/api/v1/tickets/' + tickets.id,ticket)
    //         .then(function(response) {
    //             if (response.status == 200) {
    //                 swal(
    //                     $translate.instant('Succés'),
    //                     $translate.instant('tickets modifiée avec succés'),
    //                     'success'
    //                 ).then(function() {
    //                     $window.location.reload();
    //                 });
    //             } else {}
    //         })
    //         .then(null, function(error) {
    //             console.log(error);
    //         });
    // }
    // vm.ajouter = function(ticket) {
    //     WS.post('https://api.sav.priorityexpeditions.com/api/v1/tickets', ticket)
    //         .then(function(response) {
    //             if (response.data.success == true) {
    //                 swal(
    //                     $translate.instant('Succès'),
    //                     $translate.instant('ticket ajoutée avec Succès'),
    //                     'success'
    //                 ).then(function() {
    //                     $window.location.reload();
    //                 });

    //             } else if (response.data.success == false) {
    //                 swal('Error',
    //                     $translate.instant(response.data.message),
    //                     'error');
    //             }
    //         })
    //         .then(null, function(error) {
    //             console.log(error);
    //         });
    // }


    vm.get = function() {
        WS.get('supports', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.tickets = response.data;
                    
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

 
    vm.ajouter = function(tickets) {

       WS.post('supports', tickets, 'POST')
       .then(function (response) {
           if (response.data.success == true) {

               swal(
                   $translate.instant('Succès'),
                   $translate.instant('ticket ajoutée avec Succès'),
                    'success'
                ).then(function() {
                    $window.location.reload();
                });
           } else {
               swal(
                   $translate.instant('Error'), 
                   $translate.instant(response.data.message),
                   'error'
                );
           }
       }, function (error) {
           console.log(error);
       });
    }

 

   vm.selectClients = function () {
       WS.get('clients/selectClients', vm.access)
           .then(function (response) {
               if (response.status == 200) {
                   vm.clients = response.data;

                   $scope.$apply();
               } else { }
           })
           .then(null, function (error) {
               console.log(error);
           });
   };
}
export default {
    name: 'ticketsController',
    fn: ticketsController
};